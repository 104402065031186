var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "main_layer py-2", attrs: { fluid: "" } },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "9" } },
                [
                  _vm.isUserCustomer
                    ? _c(
                        "p",
                        { staticClass: "fs-36 fw-600 white-color mb-1" },
                        [_vm._v("Личный кабинет заказчика")]
                      )
                    : _vm._e(),
                  _vm.isUserProvider
                    ? _c(
                        "p",
                        { staticClass: "fs-36 fw-600 white-color mb-1" },
                        [_vm._v("Личный кабинет поставщика")]
                      )
                    : _vm._e(),
                  _c("p", { staticClass: "fs-14 greyBg-color mb-2" }, [
                    _vm._v(" Наименование организации: "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$store.state.organization.short_name)),
                    ]),
                  ]),
                  _c("p", { staticClass: "fs-14 greyBg-color mb-2" }, [
                    _vm._v(" Пользователь: "),
                    _c("strong", [_vm._v(_vm._s(_vm.$store.state.user.name))]),
                    _c("span", { staticClass: "pl-5" }, [
                      _vm._v(" Логин: "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$store.state.user.username)),
                      ]),
                    ]),
                  ]),
                  _vm.features.telegram_bot
                    ? _c("p", { staticClass: "fs-14 greyBg-color mb-2" }, [
                        _vm._v(" ID Пользователя для Telegram: "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$store.state.user.id)),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.isUserCustomer
                    ? _c(
                        "b-btn",
                        {
                          staticClass: "my-1 mr-2",
                          attrs: {
                            to: {
                              name: "purchases.grouped.item.create",
                              params: { group: "fl44" },
                            },
                            variant: "custom-white",
                          },
                        },
                        [_vm._v("СОЗДАТЬ ЗАКУПКУ 44-ФЗ ")]
                      )
                    : _vm._e(),
                  _vm.isUserCustomer
                    ? _c(
                        "b-btn",
                        {
                          staticClass: "my-1 mx-2",
                          attrs: {
                            to: {
                              name: "ExternalPurchaseCreate",
                              params: { mode: "create" },
                            },
                            variant: "custom-outline-light",
                          },
                        },
                        [_vm._v("ДОБАВИТЬ ВНЕСИСТЕМНУЮ ЗАКУПКУ 44-ФЗ ")]
                      )
                    : _vm._e(),
                  _vm.$store.getters.isLoggedIn &&
                  _vm.orgRoleIsPermitted(_vm.$orgRoles.CUSTOMER_FL223)
                    ? [
                        _vm.$store.state.organization.settings.fl223.allowed
                          ? [
                              _c(
                                "b-btn",
                                {
                                  staticClass: "my-1 mr-2",
                                  attrs: {
                                    to: { name: "Fl223PurchaseCreate" },
                                    variant: "custom-white",
                                  },
                                },
                                [_vm._v(" СОЗДАТЬ ЗАКУПКУ 223-ФЗ ")]
                              ),
                            ]
                          : [
                              _c(
                                "b-button",
                                {
                                  staticClass: "my-1 mr-2",
                                  attrs: {
                                    id: "popover-create-fl223",
                                    variant: "custom-white",
                                  },
                                },
                                [_vm._v(" СОЗДАТЬ ЗАКУПКУ 223-ФЗ ")]
                              ),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target: "popover-create-fl223",
                                    triggers: "hover",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "fs-12" },
                                    [
                                      _vm._v(
                                        " Для создания закупки 223-ФЗ требуется дополнительная информация. Пожалуйста перейдите в "
                                      ),
                                      _c(
                                        "router-link",
                                        {
                                          staticStyle: {
                                            color: "var(--green-color)",
                                            "text-decoration": "underline",
                                          },
                                          attrs: {
                                            to: {
                                              name: "CabinetFl223Settings",
                                            },
                                            "active-class": "",
                                            "exact-active-class": "",
                                          },
                                        },
                                        [_vm._v("настройки")]
                                      ),
                                      _vm._v(". "),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                      ]
                    : _vm._e(),
                  _vm.$store.getters.isLoggedIn &&
                  _vm.orgRoleIsPermitted(_vm.$orgRoles.CUSTOMER_COMMERCIAL)
                    ? [
                        [
                          _c(
                            "b-btn",
                            {
                              staticClass: "my-1 mr-2",
                              attrs: {
                                to: { name: "CommercialPurchaseCreate" },
                                variant: "custom-white",
                              },
                            },
                            [_vm._v(" СОЗДАТЬ КОММЕРЧЕСКУЮ ЗАКУПКУ ")]
                          ),
                        ],
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c("b-col", { attrs: { md: "3" } }, [
                _c(
                  "div",
                  { staticClass: "text-right mr-2" },
                  [
                    _vm.isUserProvider
                      ? _c(
                          "b-btn",
                          {
                            staticClass: "my-1 mx-2 w-100",
                            attrs: {
                              "active-class": "",
                              "exact-active-class": "",
                              tag: "button",
                              to: { name: "OrganizationReliabilityReport" },
                              variant: "custom-white",
                            },
                          },
                          [_vm._v("Проверка контрагентов ")]
                        )
                      : _vm._e(),
                    _vm.isUserProvider && _vm.features.marketplace_integration
                      ? _c(
                          "b-btn",
                          {
                            staticClass: "text-uppercase my-1 mx-2 w-100",
                            attrs: {
                              "active-class": "",
                              "exact-active-class": "",
                              variant: "custom-white",
                            },
                            on: { click: _vm.goToBashmarket },
                          },
                          [_vm._v("ПЕРЕЙТИ НА БАШМАРКЕТ ")]
                        )
                      : _vm._e(),
                    _vm.isUserProvider && _vm.getCertificateNeedToBeProlonged
                      ? _c(
                          "b-btn",
                          {
                            staticClass: "text-uppercase my-1 mx-2 w-100",
                            attrs: {
                              href: _vm.$links.prolong_certificate,
                              "active-class": "",
                              "exact-active-class": "",
                              target: "_blank",
                              variant: "custom-danger",
                            },
                          },
                          [_vm._v("Продлить ЭЦП ")]
                        )
                      : _vm._e(),
                    _vm.isUserProvider && !_vm.getOrganizationHasPayments
                      ? _c(
                          "b-btn",
                          {
                            staticClass: "text-uppercase my-1 mx-2 w-100",
                            attrs: {
                              to: { name: "ProviderAccountCabinet" },
                              "active-class": "",
                              "exact-active-class": "",
                              variant: "custom-danger",
                            },
                          },
                          [_vm._v(" Пополнить лицевой счет ")]
                        )
                      : _vm._e(),
                    _vm.isUserProvider &&
                    !_vm.$store.state.user.is_chatme_notifiable &&
                    _vm.features.telegram_bot
                      ? _c(
                          "b-btn",
                          {
                            staticClass: "text-uppercase my-1 mx-2 w-100",
                            attrs: {
                              href: _vm.$links.telegram_events_bot,
                              target: "_blank",
                              variant: "custom-white",
                            },
                          },
                          [
                            _vm._v(
                              "Подключить уведомления о событиях в ИС и размещении новых извещений "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.isUserCustomer &&
                    !_vm.$store.state.user.is_chatme_notifiable &&
                    _vm.features.telegram_bot
                      ? _c(
                          "b-btn",
                          {
                            staticClass: "text-uppercase my-1 mx-2 w-100",
                            attrs: {
                              href: _vm.$links.telegram_events_bot,
                              target: "_blank",
                              variant: "custom-white",
                            },
                          },
                          [_vm._v("Подключить уведомления о событиях в ИС ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }