var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$store.getters.isLoggedIn &&
    _vm.orgRoleIsPermitted(_vm.$orgRoles.CUSTOMER_FL223)
    ? _c(
        "div",
        {
          staticClass: "purchases_create atmo-page atmo-hash-jump-point",
          attrs: { id: "atmo-top" },
        },
        [
          _vm._m(0),
          _c("purchase-header-block", {
            attrs: {
              organization: _vm.customer,
              title: _vm.isModeEdit
                ? `Редактирование извещения № ${_vm.form.reg_number} 223-ФЗ`
                : "Объявление о закупочной сессии 223-ФЗ",
            },
          }),
          _c(
            "b-container",
            {
              staticClass: "my-4 position-relative purchase_session",
              staticStyle: { "background-color": "#f5f5f7" },
            },
            [
              _c(
                "div",
                { staticClass: "mx-1 mb-2" },
                [
                  _c(
                    "b-btn",
                    {
                      staticClass: "fs-14 my-2 text-uppercase",
                      staticStyle: { color: "white !important" },
                      attrs: {
                        variant: "danger",
                        size: "lg",
                        href: _vm.$links.fl223_purchase_create_manual,
                        target: "_blank",
                      },
                    },
                    [
                      _c("i", { staticClass: "atmo-icon-play" }),
                      _vm._v(" Инструкция по созданию извещения"),
                    ]
                  ),
                ],
                1
              ),
              _c("form-row-edit-text", {
                attrs: { label: "Дополнительная контактная информация" },
                model: {
                  value: _vm.form.purchase_terms.contact_info,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.purchase_terms, "contact_info", $$v)
                  },
                  expression: "form.purchase_terms.contact_info",
                },
              }),
              _c("form-row-edit-textarea", {
                attrs: {
                  rows: 5,
                  label:
                    "Примечание к закупке (отображается только в ЛК Заказчика)",
                },
                model: {
                  value: _vm.form.purchase_terms.comment,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.purchase_terms, "comment", $$v)
                  },
                  expression: "form.purchase_terms.comment",
                },
              }),
              _c(
                "h2",
                {
                  staticClass: "atmo-hash-jump-point font-weight-bold",
                  attrs: { id: "purchase-terms" },
                },
                [_vm._v("Условия закупки")]
              ),
              _c("form-row-edit-text", {
                attrs: {
                  label: "Объект закупки",
                  description:
                    "Укажите объект закупки по договору (например: строительные материалы, канцелярские товары и т.п.)",
                  v: _vm.$v.form.purchase_terms.purchase_object,
                },
                model: {
                  value: _vm.form.purchase_terms.purchase_object,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.purchase_terms, "purchase_object", $$v)
                  },
                  expression: "form.purchase_terms.purchase_object",
                },
              }),
              _c("form-row-single-select", {
                attrs: {
                  disabled:
                    _vm.restrictions.was_canceled &&
                    _vm.form.purchase_terms.deliverable_group_id &&
                    !_vm.$v.form.purchase_terms.deliverable_group_id.$anyDirty,
                  options: _vm.dictionaries.deliverable_groups,
                  v: _vm.$v.form.purchase_terms.deliverable_group_id,
                  label: "Предмет закупки",
                  placeholder: "Выберите предмет закупки",
                },
                model: {
                  value: _vm.form.purchase_terms.deliverable_group_id,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.purchase_terms,
                      "deliverable_group_id",
                      $$v
                    )
                  },
                  expression: "form.purchase_terms.deliverable_group_id",
                },
              }),
              _c("form-row-single-select", {
                attrs: {
                  disabled: _vm.restrictions.was_canceled,
                  options: _vm.dictionaries.duration_types,
                  v: _vm.$v.form.purchase_terms.duration_type,
                  label: "Тип закупочной сессии",
                  placeholder: "Выберите тип закупочной сессии",
                },
                model: {
                  value: _vm.form.purchase_terms.duration_type,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.purchase_terms, "duration_type", $$v)
                  },
                  expression: "form.purchase_terms.duration_type",
                },
              }),
              _c("form-row-edit-number", {
                attrs: {
                  disabled: _vm.restrictions.was_canceled,
                  label: `Продолжительность закупочной сессии${_vm.currentDurationTypeSuffix}`,
                  "max-value": _vm.currentDurationTypeMaxLength,
                  "min-value": _vm.currentDurationTypeMinLength,
                  v: _vm.$v.form.purchase_terms.duration,
                },
                model: {
                  value: _vm.form.purchase_terms.duration,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.purchase_terms, "duration", $$v)
                  },
                  expression: "form.purchase_terms.duration",
                },
              }),
              _c("form-row-single-select", {
                attrs: {
                  options: _vm.dictionaries.payment_forms,
                  v: _vm.$v.form.purchase_terms.payment_form,
                  label: "Вид оплаты",
                  placeholder: "Выберите вид оплаты",
                },
                model: {
                  value: _vm.form.purchase_terms.payment_form,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.purchase_terms, "payment_form", $$v)
                  },
                  expression: "form.purchase_terms.payment_form",
                },
              }),
              _c("form-row-single-select", {
                attrs: {
                  options: _vm.dictionaries.payment_terms,
                  v: _vm.$v.form.purchase_terms.payment_term,
                  label: "Условия оплаты",
                  placeholder: "Выберите условия оплаты",
                },
                model: {
                  value: _vm.form.purchase_terms.payment_term,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.purchase_terms, "payment_term", $$v)
                  },
                  expression: "form.purchase_terms.payment_term",
                },
              }),
              _c("form-row-edit-date", {
                attrs: {
                  "min-date": _vm.moment().format("YYYY-MM-DD"),
                  v: _vm.$v.form.purchase_terms
                    .planned_contract_conclusion_datetime,
                  label: "Планируемая дата заключения договора",
                },
                model: {
                  value:
                    _vm.form.purchase_terms
                      .planned_contract_conclusion_datetime,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.purchase_terms,
                      "planned_contract_conclusion_datetime",
                      $$v
                    )
                  },
                  expression:
                    "form.purchase_terms.planned_contract_conclusion_datetime",
                },
              }),
              _c("form-row-edit-date", {
                attrs: {
                  disabled:
                    !_vm.form.purchase_terms
                      .planned_contract_conclusion_datetime,
                  "min-date":
                    _vm.form.purchase_terms
                      .planned_contract_conclusion_datetime,
                  v: _vm.$v.form.purchase_terms
                    .planned_contract_execution_datetime,
                  label: "Планируемая дата исполнения договора",
                },
                model: {
                  value:
                    _vm.form.purchase_terms.planned_contract_execution_datetime,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.purchase_terms,
                      "planned_contract_execution_datetime",
                      $$v
                    )
                  },
                  expression:
                    "form.purchase_terms.planned_contract_execution_datetime",
                },
              }),
              _c("form-row-single-line-document-upload", {
                attrs: {
                  "label-cols-lg": 3,
                  v: _vm.$v.form.purchase_terms.contract_draft_attachment,
                  title: "Проект договора",
                },
                model: {
                  value: _vm.form.purchase_terms.contract_draft_attachment,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.purchase_terms,
                      "contract_draft_attachment",
                      $$v
                    )
                  },
                  expression: "form.purchase_terms.contract_draft_attachment",
                },
              }),
              _c(
                "h2",
                {
                  staticClass: "atmo-hash-jump-point font-weight-bold",
                  attrs: { id: "supplier-requirements" },
                },
                [_vm._v("Требования к поставщикам")]
              ),
              _c("form-row-edit-checkbox", {
                attrs: {
                  "label-cols-sm": 9,
                  "label-cols-lg": 6,
                  label: "Отсутствие в реестре недобросовестных поставщиков",
                },
                model: {
                  value: _vm.form.supplier_requirements.only_not_rnp,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.supplier_requirements,
                      "only_not_rnp",
                      $$v
                    )
                  },
                  expression: "form.supplier_requirements.only_not_rnp",
                },
              }),
              _c("form-row-edit-checkbox", {
                attrs: {
                  "label-cols-sm": 9,
                  "label-cols-lg": 6,
                  label:
                    "Участником закупки могут быть только субъекты малого предпринимательства (СМП)",
                },
                model: {
                  value: _vm.form.supplier_requirements.only_smp,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.supplier_requirements, "only_smp", $$v)
                  },
                  expression: "form.supplier_requirements.only_smp",
                },
              }),
              _c("form-row-edit-checkbox", {
                attrs: {
                  "label-cols-sm": 9,
                  "label-cols-lg": 6,
                  label: "Дополнительные требования к участникам закупки",
                },
                on: { input: _vm.changedHasAdditionalRequirements },
                model: {
                  value:
                    _vm.form.supplier_requirements.has_additional_requirements,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.supplier_requirements,
                      "has_additional_requirements",
                      $$v
                    )
                  },
                  expression:
                    "form.supplier_requirements.has_additional_requirements",
                },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.form.supplier_requirements
                          .has_additional_requirements,
                      expression:
                        "form.supplier_requirements.has_additional_requirements",
                    },
                  ],
                  staticClass: "atmo-nested-section",
                },
                [
                  _c("section", { staticStyle: { width: "100%" } }, [
                    _c("div", { staticClass: "table-responsive" }, [
                      _c(
                        "table",
                        {
                          staticClass: "atmo-content-table mb-1",
                          class: {
                            "atmo-empty": _vm.isEmpty(
                              _vm.form.supplier_requirements.additional
                            ),
                            "is-invalid":
                              _vm.$v.form.supplier_requirements.additional
                                .$invalid,
                          },
                        },
                        [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { staticClass: "atmo-ctc-place" }, [
                                _vm._v("№"),
                              ]),
                              _c(
                                "th",
                                { staticClass: "atmo-ctc-width-45pct" },
                                [_vm._v("Описание")]
                              ),
                              _c(
                                "th",
                                { staticClass: "atmo-ctc-width-45pct" },
                                [
                                  _vm._v(
                                    "Причина или цель установки требования"
                                  ),
                                ]
                              ),
                              _c(
                                "th",
                                { staticClass: "atmo-ctc-command-remove" },
                                [_vm._v(" ")]
                              ),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            [
                              _vm._l(
                                _vm.form.supplier_requirements.additional,
                                function (requirement, index) {
                                  return _c("tr", { key: index }, [
                                    _c("td", {
                                      staticClass: "atmo-ctc-place",
                                      domProps: {
                                        textContent: _vm._s(index + 1),
                                      },
                                    }),
                                    _c(
                                      "td",
                                      { staticClass: "atmo-ctc-width-45pct" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "atmo-form__field atmo-inverse",
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    requirement.description,
                                                  expression:
                                                    "requirement.description",
                                                },
                                              ],
                                              attrs: {
                                                id: `requirement-additional-item-${
                                                  index + 1
                                                }`,
                                                type: "text",
                                                required: "",
                                                min: "1",
                                              },
                                              domProps: {
                                                value: requirement.description,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    requirement,
                                                    "description",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "atmo-ctc-width-45pct" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "atmo-form__field atmo-inverse",
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: requirement.reason,
                                                  expression:
                                                    "requirement.reason",
                                                },
                                              ],
                                              attrs: {
                                                type: "text",
                                                required: "",
                                                min: "1",
                                              },
                                              domProps: {
                                                value: requirement.reason,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    requirement,
                                                    "reason",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "atmo-ctc-command-remove",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "atmo-button-icon",
                                            attrs: {
                                              title: "Удалить описание",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.removeAdditionalRequirement(
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "atmo-icon-trash",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                }
                              ),
                              _vm.isEmpty(
                                _vm.form.supplier_requirements.additional
                              )
                                ? _c("tr", { staticClass: "atmo-ctc-center" }, [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "atmo-centered",
                                        attrs: { colspan: "3" },
                                      },
                                      [
                                        _vm._v(
                                          "Не добавлено описаний документов"
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]),
                    _c("div", [
                      _vm.isEmpty(_vm.form.supplier_requirements.additional)
                        ? _c("p", { staticClass: "atmo-error" }, [
                            _vm._v(
                              "Добавьте и опишите дополнительные требования."
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "button",
                        {
                          staticClass: "atmo-button",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.addAdditionalRequirement.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v("Добавить описание требования")]
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "h2",
                {
                  staticClass: "atmo-hash-jump-point font-weight-bold",
                  attrs: { id: "supply-terms" },
                },
                [_vm._v("Условия поставки")]
              ),
              _c("form-row-edit-addresses-new", {
                attrs: {
                  value: _vm.form.delivery_terms.addresses,
                  label: "Адрес для доставки/самовывоза",
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(
                      _vm.form.delivery_terms,
                      "addresses",
                      $event
                    )
                  },
                },
              }),
              _c("form-row-edit-textarea", {
                attrs: {
                  rows: 4,
                  label:
                    "График поставки товаров (выполнения работ, оказания услуг)",
                },
                model: {
                  value: _vm.form.delivery_terms.schedule,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.delivery_terms, "schedule", $$v)
                  },
                  expression: "form.delivery_terms.schedule",
                },
              }),
              _c("form-row-specification-table", {
                staticClass: "atmo-hash-jump-point",
                attrs: {
                  id: "specification",
                  "spec-error-message": _vm.specErrorMessage,
                  "spec-errors": _vm.specErrors,
                  "spec-sum": _vm.specSum,
                  v: _vm.$v.form.deliverables,
                  "with-count-edit": "",
                  "with-price": "",
                },
                model: {
                  value: _vm.form.deliverables,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deliverables", $$v)
                  },
                  expression: "form.deliverables",
                },
              }),
              _c("form-row-upload-multiple-documents", {
                staticClass: "atmo-hash-jump-point",
                attrs: {
                  id: "additional-documents",
                  title: "Дополнительные документы",
                  v: _vm.$v.form.additional_documents,
                },
                model: {
                  value: _vm.form.additional_documents,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "additional_documents", $$v)
                  },
                  expression: "form.additional_documents",
                },
              }),
              _c(
                "b-row",
                { staticClass: "my-3 mt-5" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mx-2 text-uppercase",
                          attrs: { variant: "custom-outline-secondary" },
                          on: { click: _vm.onCancel },
                        },
                        [_vm._v("Отменить")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase mx-2",
                          attrs: { variant: "custom-danger" },
                          on: { click: _vm.saveDraft },
                        },
                        [_vm._v("Сохранить черновик")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase mx-2",
                          attrs: {
                            disabled:
                              !_vm.isValid || !_vm.$store.getters.getCanSign,
                            variant: "custom-green",
                          },
                          on: { click: _vm.validateForm },
                        },
                        [_vm._v("Подписать и сохранить")]
                      ),
                      !_vm.$store.getters.getCanSign
                        ? _c("span", { staticClass: "text-danger pt-1" }, [
                            _vm._v(
                              "Подписание возможно только при авторизации при помощи ЭЦП"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-modal",
                {
                  ref: "sign-form-modal",
                  attrs: {
                    centered: "",
                    "no-close-on-backdrop": "",
                    "no-close-on-esc": "",
                    scrollable: "",
                    size: "lg",
                    title: "Подписать извещение",
                  },
                  on: { close: _vm.cancelSignModal },
                  model: {
                    value: _vm.showSignModal,
                    callback: function ($$v) {
                      _vm.showSignModal = $$v
                    },
                    expression: "showSignModal",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-block text-left" },
                    [
                      _c(
                        "b-tabs",
                        { attrs: { "content-class": "mt-3 px-3" } },
                        [
                          _c(
                            "b-tab",
                            { attrs: { active: "" } },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "span",
                                  { staticClass: "system_link-style fs-16" },
                                  [_vm._v("Форма")]
                                ),
                              ]),
                              _c("h4", { staticClass: "font-weight-bold" }, [
                                _vm._v("Информация о заказчике"),
                              ]),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  label: "Наименование организации",
                                  value: _vm.customer.name,
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  label: "ИНН",
                                  value: _vm.customer.inn,
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  label: "КПП",
                                  value: _vm.customer.kpp,
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  label: "ОГРН",
                                  value: _vm.customer.ogrn,
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  label: "Адрес",
                                  value: _vm.customer.name,
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  label: "Наименование заказчика",
                                  value: _vm.customer.legal_address,
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  label: "Дополнительная контактная информация",
                                  value: _vm.form.purchase_terms.contact_info,
                                },
                              }),
                              _c("hr"),
                              _c("h4", { staticClass: "font-weight-bold" }, [
                                _vm._v("Условия закупки"),
                              ]),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  label: "Объект закупки",
                                  value:
                                    _vm.form.purchase_terms.purchase_object,
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  label: "Предмет закупки",
                                  value:
                                    _vm.dictionaries.deliverable_groups?.find(
                                      (item) =>
                                        item.id ===
                                        _vm.form.purchase_terms
                                          .deliverable_group_id
                                    )?.name,
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  label: "Тип закупочной сессии",
                                  value: _vm.dictionaries.duration_types?.find(
                                    (item) =>
                                      item.id ===
                                      _vm.form.purchase_terms.duration_type
                                  )?.name,
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  label: `Продолжительность закупочной сессии${_vm.currentDurationTypeSuffix}`,
                                  value: _vm.form.purchase_terms.duration,
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  label: "Вид оплаты",
                                  value: _vm.dictionaries.payment_forms?.find(
                                    (item) =>
                                      item.id ===
                                      _vm.form.purchase_terms.payment_form
                                  )?.name,
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  label: "Условия оплаты",
                                  value: _vm.dictionaries.payment_terms?.find(
                                    (item) =>
                                      item.id ===
                                      _vm.form.purchase_terms.payment_term
                                  )?.name,
                                },
                              }),
                              _c("text-row-date", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  label: "Планируемая дата заключения договора",
                                  value:
                                    _vm.form.purchase_terms
                                      .planned_contract_conclusion_datetime,
                                },
                              }),
                              _c("text-row-date", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  label: "Планируемая дата исполнения договора",
                                  value:
                                    _vm.form.purchase_terms
                                      .planned_contract_execution_datetime,
                                },
                              }),
                              _c("text-row-price", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  label: "Сумма закупки",
                                  value: _vm.specSum,
                                },
                              }),
                              _c("text-row-single-document", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  label: "Проект договора",
                                  value:
                                    _vm.form.purchase_terms
                                      .contract_draft_attachment,
                                },
                              }),
                              _c("hr"),
                              _c("h4", { staticClass: "font-weight-bold" }, [
                                _vm._v("Требования к поставщикам"),
                              ]),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 6,
                                  "label-cols-sm": 6,
                                  label:
                                    "Отсутствие в реестре недобросовестных поставщиков",
                                  value: _vm.form.supplier_requirements
                                    .only_not_rnp
                                    ? "ДА"
                                    : "НЕТ",
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 6,
                                  "label-cols-sm": 6,
                                  label:
                                    "Участником закупки могут быть только субъекты малого предпринимательства (СМП)",
                                  value: _vm.form.supplier_requirements.only_smp
                                    ? "ДА"
                                    : "НЕТ",
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 6,
                                  "label-cols-sm": 6,
                                  label:
                                    "Дополнительные требования к участникам закупки",
                                  value: _vm.form.supplier_requirements
                                    .has_additional_requirements
                                    ? "ДА"
                                    : "НЕТ",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.form.supplier_requirements
                                          .has_additional_requirements,
                                      expression:
                                        "form.supplier_requirements.has_additional_requirements",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "section",
                                    { staticStyle: { width: "100%" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "table-responsive" },
                                        [
                                          _c(
                                            "table",
                                            {
                                              staticClass:
                                                "atmo-content-table mb-0",
                                            },
                                            [
                                              _c("thead", [
                                                _c("tr", [
                                                  _c(
                                                    "th",
                                                    {
                                                      staticClass:
                                                        "atmo-ctc-width-45pct",
                                                    },
                                                    [_vm._v("Описание")]
                                                  ),
                                                  _c(
                                                    "th",
                                                    {
                                                      staticClass:
                                                        "atmo-ctc-width-45pct",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Причина или цель установки требования"
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]),
                                              _c(
                                                "tbody",
                                                _vm._l(
                                                  _vm.form.supplier_requirements
                                                    .additional,
                                                  function (
                                                    requirement,
                                                    index
                                                  ) {
                                                    return _c(
                                                      "tr",
                                                      { key: index },
                                                      [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "atmo-ctc-width-45pct",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                requirement.description
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "atmo-ctc-width-45pct",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                requirement.reason
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.form.delivery_terms.addresses.length > 0 ||
                              _vm.form.delivery_terms.schedule
                                ? [
                                    _c("hr"),
                                    _c(
                                      "h4",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v("Условия поставки")]
                                    ),
                                    _vm.form.delivery_terms.addresses.length > 0
                                      ? _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              "label-cols-sm": 4,
                                              "label-cols-lg": 4,
                                              label:
                                                "Адреса для доставки/самовывоза:",
                                              "label-class":
                                                "fs-14 grey-color fw-700 d-flex align-items-baseline",
                                            },
                                          },
                                          [
                                            _c(
                                              "ul",
                                              _vm._l(
                                                _vm.form.delivery_terms
                                                  .addresses,
                                                function (address, index) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: index,
                                                      staticClass:
                                                        "fs-14 grey-color my-auto col-form-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          address.address ??
                                                            address
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("text-row", {
                                      attrs: {
                                        compact: true,
                                        "label-cols-lg": 4,
                                        "label-cols-sm": 4,
                                        label:
                                          "График поставки товаров (выполнения работ, оказания услуг)",
                                        value: _vm.form.delivery_terms.schedule,
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _c("hr"),
                              _c("h4", { staticClass: "font-weight-bold" }, [
                                _vm._v("Спецификация"),
                              ]),
                              _vm.form.deliverables.length > 0
                                ? _c("b-form-group", [
                                    _c(
                                      "ul",
                                      _vm._l(
                                        _vm.form.deliverables,
                                        function (item, index) {
                                          return _c(
                                            "li",
                                            {
                                              key: index,
                                              staticClass:
                                                "fs-14 grey-color my-auto col-form-label",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.name) +
                                                  " - " +
                                                  _vm._s(item.count) +
                                                  ", " +
                                                  _vm._s(
                                                    item.okei.local_symbol
                                                  ) +
                                                  " "
                                              ),
                                              item.price
                                                ? _c("span", [
                                                    _vm._v(
                                                      "* " +
                                                        _vm._s(item.price) +
                                                        ", руб."
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ])
                                : _vm._e(),
                              _c("hr"),
                              _c("h4", { staticClass: "font-weight-bold" }, [
                                _vm._v("Дополнительные документы"),
                              ]),
                              _vm.form.additional_documents.length > 0
                                ? _c("text-row-documents", {
                                    attrs: {
                                      label: "Дополнительные документы",
                                    },
                                    model: {
                                      value: _vm.form.additional_documents,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "additional_documents",
                                          $$v
                                        )
                                      },
                                      expression: "form.additional_documents",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "b-tab",
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "span",
                                  { staticClass: "system_link-style fs-16" },
                                  [_vm._v("XML")]
                                ),
                              ]),
                              !_vm.isSignedXml
                                ? _c("b-form-textarea", {
                                    staticClass: "text-monospace fs-12",
                                    attrs: {
                                      disabled: "",
                                      rows: "20",
                                      "no-resize": "",
                                      "no-auto-shrink": "",
                                      wrap: "off",
                                    },
                                    model: {
                                      value: _vm.formXml,
                                      callback: function ($$v) {
                                        _vm.formXml = $$v
                                      },
                                      expression: "formXml",
                                    },
                                  })
                                : _vm._e(),
                              _vm.isSignedXml
                                ? _c("b-form-textarea", {
                                    staticClass: "text-monospace fs-12",
                                    attrs: { disabled: "", rows: "20" },
                                    model: {
                                      value: _vm.signedFormXml,
                                      callback: function ($$v) {
                                        _vm.signedFormXml = $$v
                                      },
                                      expression: "signedFormXml",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "w-100 px-3",
                      attrs: { slot: "modal-footer" },
                      slot: "modal-footer",
                    },
                    [
                      _c("text-row", {
                        attrs: {
                          label: "Сертификат",
                          value: _vm.certificate?.subjectName,
                        },
                      }),
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-2 text-uppercase",
                          attrs: {
                            disabled: _vm.isDataSending,
                            variant: "custom-outline-secondary",
                          },
                          on: { click: _vm.cancelSignModal },
                        },
                        [_vm._v("Отмена")]
                      ),
                      !_vm.isSignedXml
                        ? _c(
                            "b-overlay",
                            {
                              staticClass: "d-inline-block",
                              attrs: {
                                show: _vm.isSigningXml,
                                opacity: "0.6",
                                rounded: "",
                                "spinner-small": "",
                                "spinner-variant": "primary",
                              },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "text-uppercase",
                                  attrs: {
                                    disabled: !_vm.certificate,
                                    variant: "custom-green",
                                  },
                                  on: { click: _vm.signForm },
                                },
                                [_vm._v("Подписать")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isSignedXml
                        ? _c(
                            "b-overlay",
                            {
                              staticClass: "d-inline-block",
                              attrs: {
                                show: _vm.isDataSending,
                                opacity: "0.6",
                                rounded: "",
                                "spinner-small": "",
                                "spinner-variant": "primary",
                              },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "text-uppercase",
                                  attrs: {
                                    disabled: !_vm.certificate,
                                    variant: "custom-green",
                                  },
                                  on: { click: _vm.sendData },
                                },
                                [_vm._v("Отправить")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c("span", {
                staticClass: "atmo-hash-jump-point",
                attrs: { id: "atmo-bottom" },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "atmo-fast-access-menu" }, [
      _c("ul", { staticClass: "atmo-container-fixed" }, [
        _c("li", [
          _c("a", { attrs: { href: "#purchase-terms" } }, [
            _vm._v("Условия закупки"),
          ]),
        ]),
        _c("li", [
          _c("a", { attrs: { href: "#supplier-requirements" } }, [
            _vm._v("Требования к поставщикам"),
          ]),
        ]),
        _c("li", [
          _c("a", { attrs: { href: "#supply-terms" } }, [
            _vm._v("Условия поставки"),
          ]),
        ]),
        _c("li", [
          _c("a", { attrs: { href: "#specification" } }, [
            _vm._v("Спецификация"),
          ]),
        ]),
        _c("li", [
          _c("a", { attrs: { href: "#additional-documents" } }, [
            _vm._v("Дополнительные документы"),
          ]),
        ]),
        _c("section", { staticClass: "atmo-group" }, [
          _c("li", { attrs: { title: "К верху страницы" } }, [
            _c(
              "a",
              { staticClass: "atmo-button-icon", attrs: { href: "#atmo-top" } },
              [_c("i", { staticClass: "atmo-icon-angle-up" })]
            ),
          ]),
          _c("li", { attrs: { title: "К низу страницы" } }, [
            _c(
              "a",
              {
                staticClass: "atmo-button-icon",
                attrs: { href: "#atmo-bottom" },
              },
              [_c("i", { staticClass: "atmo-icon-angle-down" })]
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }