<template>
    <div>
        <h3 class="grey-color mb-3 main_text font-weight-bold fs-28">Создание пользователя</h3>

        <form autocomplete="off">
            <form-row-edit-text v-model="form.name" :v="$v.form.name" autocomplete="name" label="ФИО" />
            <form-row-edit-phone :v="$v.form.phone" :value="form.phone" label="Телефон" @value="form.phone = $event" />
            <form-row-single-select v-model="form.post" :options="postOptions" :v="$v.form.post" autocomplete="organization-title" label="Должность" placeholder="Выберите должность" />
            <form-row-edit-text v-model="form.password" :v="$v.form.password" autocomplete="new-password" label="Пароль" type="password" @value="form.password = $event" />
            <form-row-edit-text v-model="form.password_confirmation" :v="$v.form.password_confirmation" autocomplete="new-password" label="Подтверждение пароля" type="password" @value="form.password_confirmation = $event" />
            <form-row-edit-email :v="$v.form.email" :value="form.email" autocomplete="email" label="Email" @value="form.email = $event" />
            <form-row-edit-checkbox v-model="form.signs_with_poa" label="Используется МЧД" />
            <b-row>
                <b-col class="text-right" md="12">
                    <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <b-button class="mx-1" variant="custom-outline-secondary" @click="onCancel"> Отмена</b-button>
                    </b-overlay>
                    <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <b-button :disabled="$v.form.$invalid" class="mx-1" variant="custom-green" @click="onSubmit">Добавить</b-button>
                    </b-overlay>
                </b-col>
            </b-row>
        </form>
    </div>
</template>

<script>
import { email, helpers, minLength, required, sameAs } from 'vuelidate/lib/validators';

const phone = helpers.regex('phone', /^7[0-9+]{10}$/);
const allowable = helpers.regex('allowable', /^[a-zA-Z0-9-_=+~!@#$%^&*() ,.<>?/\\|`]+$/);
const containsSymbols = helpers.withParams({ type: 'containsSymbols' }, (value) => (value ? /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value) : true));

export default {
    name: 'UserCreate',
    metaInfo: {
        title: 'Пользователи организации - Создание пользователя'
    },
    data() {
        return {
            isDataSending: false,
            showErrors: true,
            loading: true,
            form: {
                name: '',
                phone: '',
                email: '',
                post: '',
                password: '',
                password_confirmation: '',
                signs_with_poa: false
            },
            postOptions: [
                { id: 'Руководитель организации', name: 'Руководитель организации' },
                { id: 'Администратор организации', name: 'Администратор организации' },
                { id: 'Лицо, уполномоченное на размещение информации и документов', name: 'Лицо, уполномоченное на размещение информации и документов' },
                { id: 'Лицо, имеющее право подписи документов от имени организации', name: 'Лицо, имеющее право подписи документов от имени организации' }
            ]
        };
    },
    validations: {
        form: {
            name: { required },
            post: { required },
            phone: { required, phone },
            email: { required, email },
            password: { required, minLength: minLength(8), allowable, containsSymbols },
            password_confirmation: {
                required,
                confirmPassword: sameAs((form) => form.password)
            }
        }
    },
    methods: {
        onCancel() {
            this.$router.push({ name: 'UsersList' }).catch(() => {});
        },
        async onSubmit() {
            this.showErrors = true;
            this.isDataSending = true;
            if (await this.$api.cabinet.users.post(this.form)) {
                this.onCancel();
            }
            this.isDataSending = false;
        }
    }
};
</script>
