var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showForm
        ? _c(
            "b-container",
            { staticClass: "register_page_header_block", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c("div", { staticClass: "fs-52 fw-600 white-color pt-3" }, [
                    _vm._v("Регистрация поставщика"),
                  ]),
                  _c(
                    "b-tabs",
                    {
                      staticClass: "w-100 register_provider py-3",
                      attrs: { "content-class": "invisible" },
                      model: {
                        value: _vm.tabIndex,
                        callback: function ($$v) {
                          _vm.tabIndex = $$v
                        },
                        expression: "tabIndex",
                      },
                    },
                    [
                      _c(
                        "b-tab",
                        [
                          _c(
                            "registration-tab-header",
                            {
                              attrs: {
                                slot: "title",
                                "has-errors": _vm.$v.form.organization.$invalid,
                                number: "01",
                              },
                              slot: "title",
                            },
                            [
                              _vm._v(
                                " Регистрационные и контактные данные организации"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        [
                          _c(
                            "registration-tab-header",
                            {
                              attrs: {
                                slot: "title",
                                "has-errors":
                                  _vm.$v.form.user.$invalid ||
                                  _vm.$v.form.poa.$invalid,
                                number: "02",
                              },
                              slot: "title",
                            },
                            [_vm._v(" Идентификационные данные пользователя")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        [
                          _c(
                            "registration-tab-header",
                            {
                              attrs: {
                                slot: "title",
                                "has-errors": _vm.$v.form.check_rules.$invalid,
                                number: "03",
                              },
                              slot: "title",
                            },
                            [_vm._v(" Завершение создания заявления")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showForm
        ? _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-tabs",
                    {
                      staticClass: "w-100",
                      attrs: { "nav-class": "invisible" },
                      model: {
                        value: _vm.tabIndex,
                        callback: function ($$v) {
                          _vm.tabIndex = $$v
                        },
                        expression: "tabIndex",
                      },
                    },
                    [
                      _c(
                        "b-tab",
                        [
                          _c("text-header", [_vm._v("Данные организации")]),
                          _c("text-row", {
                            attrs: {
                              value: _vm.form.organization.full_name,
                              label: "Полное наименование",
                            },
                          }),
                          _c("text-row", {
                            attrs: {
                              value: _vm.form.organization.short_name,
                              label: "Сокращенное наименование",
                            },
                          }),
                          _c("text-row", {
                            attrs: {
                              value: _vm.form.organization.manager,
                              label: "Руководитель",
                            },
                          }),
                          _c("text-row", {
                            attrs: {
                              value: _vm.form.organization.inn,
                              label: "ИНН",
                            },
                          }),
                          _vm.selectedCertificateType === "legal"
                            ? _c("text-row", {
                                attrs: {
                                  value: _vm.form.organization.kpp,
                                  label: "КПП",
                                },
                              })
                            : _vm._e(),
                          _vm.selectedCertificateType === "legal"
                            ? _c("text-row", {
                                attrs: {
                                  value: _vm.form.organization.ogrn,
                                  label: "ОГРН",
                                },
                              })
                            : _vm._e(),
                          _vm.selectedCertificateType === "individual"
                            ? _c("text-row", {
                                attrs: {
                                  value: _vm.form.organization.ogrn,
                                  label: "ОГРНИП",
                                },
                              })
                            : _vm._e(),
                          _c("form-row-edit-checkbox", {
                            attrs: {
                              label:
                                "Непосредственный производитель товаров, прямой исполнитель работ, услуг",
                            },
                            model: {
                              value: _vm.form.organization.is_isp,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.organization, "is_isp", $$v)
                              },
                              expression: "form.organization.is_isp",
                            },
                          }),
                          _c("text-header", [_vm._v("Контактная информация")]),
                          _c("form-row-edit-dadata-address", {
                            attrs: {
                              v: _vm.$v.form.organization.post_address,
                              label: "Адрес почтовый",
                            },
                            model: {
                              value: _vm.form.organization.post_address,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.organization,
                                  "post_address",
                                  $$v
                                )
                              },
                              expression: "form.organization.post_address",
                            },
                          }),
                          _c("form-row-edit-dadata-address", {
                            attrs: {
                              v: _vm.$v.form.organization.fact_address,
                              label: "Адрес фактический",
                            },
                            model: {
                              value: _vm.form.organization.fact_address,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.organization,
                                  "fact_address",
                                  $$v
                                )
                              },
                              expression: "form.organization.fact_address",
                            },
                          }),
                          _c("form-row-edit-email", {
                            attrs: {
                              "email-confirmed":
                                _vm.form.organization.email_confirmed,
                              v: _vm.$v.form.organization.email,
                              value: _vm.form.organization.email,
                              label: "Адрес электронной почты",
                              "with-validation": "",
                            },
                            on: {
                              value: function ($event) {
                                _vm.form.organization.email = $event
                              },
                              "email-confirmed": function ($event) {
                                _vm.form.organization.email_confirmed = $event
                              },
                            },
                          }),
                          _c("form-row-edit-phone", {
                            attrs: {
                              v: _vm.$v.form.organization.phone,
                              value: _vm.form.organization.phone,
                              label: "Телефон",
                            },
                            on: {
                              value: function ($event) {
                                _vm.form.organization.phone = $event
                              },
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: { label: "Адрес сайта" },
                            model: {
                              value: _vm.form.organization.site,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.organization, "site", $$v)
                              },
                              expression: "form.organization.site",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              v: _vm.$v.form.organization.contact_name,
                              label: "ФИО контактного лица",
                            },
                            model: {
                              value: _vm.form.organization.contact_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.organization,
                                  "contact_name",
                                  $$v
                                )
                              },
                              expression: "form.organization.contact_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        [
                          _c("text-header", [
                            _vm._v("Идентификационные данные пользователя"),
                          ]),
                          _c("text-row", {
                            attrs: { value: _vm.form.user.name, label: "ФИО" },
                          }),
                          _c("text-row", {
                            attrs: { value: _vm.form.user.inn, label: "ИНН" },
                          }),
                          _c("text-row", {
                            attrs: {
                              value: _vm.form.user.snils,
                              label: "СНИЛС",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              v: _vm.$v.form.user.password,
                              label: "Пароль",
                              type: "password",
                              autocomplete: "new-password",
                            },
                            on: {
                              value: function ($event) {
                                _vm.form.user.password = $event
                              },
                            },
                            model: {
                              value: _vm.form.user.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.user, "password", $$v)
                              },
                              expression: "form.user.password",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              v: _vm.$v.form.user.password_confirmation,
                              label: "Подтверждение пароля",
                              type: "password",
                              autocomplete: "new-password",
                            },
                            on: {
                              value: function ($event) {
                                _vm.form.user.password_confirmation = $event
                              },
                            },
                            model: {
                              value: _vm.form.user.password_confirmation,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.user,
                                  "password_confirmation",
                                  $$v
                                )
                              },
                              expression: "form.user.password_confirmation",
                            },
                          }),
                          _c("form-row-edit-email", {
                            attrs: {
                              "email-confirmed": _vm.form.user.email_confirmed,
                              v: _vm.$v.form.user.email,
                              value: _vm.form.user.email,
                              label: "Адрес электронной почты",
                              "with-validation": "",
                              autocomplete: "email",
                            },
                            on: {
                              value: function ($event) {
                                _vm.form.user.email = $event
                              },
                              "email-confirmed": function ($event) {
                                _vm.form.user.email_confirmed = $event
                              },
                            },
                          }),
                          _vm.$isPhoneConfirmationNeeded
                            ? _c("form-row-edit-phone", {
                                attrs: {
                                  "phone-confirmed":
                                    _vm.form.user.phone_confirmed,
                                  v: _vm.$v.form.user.phone,
                                  value: _vm.form.user.phone,
                                  label: "Телефон",
                                  "with-validation": "",
                                },
                                on: {
                                  value: function ($event) {
                                    _vm.form.user.phone = $event
                                  },
                                  "phone-confirmed": function ($event) {
                                    _vm.form.user.phone_confirmed = $event
                                  },
                                },
                              })
                            : _c("form-row-edit-phone", {
                                attrs: {
                                  v: _vm.$v.form.user.phone,
                                  value: _vm.form.user.phone,
                                  label: "Телефон",
                                },
                                on: {
                                  value: function ($event) {
                                    _vm.form.user.phone = $event
                                  },
                                },
                              }),
                          _c("form-row-single-select", {
                            attrs: {
                              options: _vm.postOptions,
                              v: _vm.$v.form.user.post,
                              autocomplete: "organization-title",
                              label: "Должность",
                              placeholder: "Выберите должность",
                            },
                            model: {
                              value: _vm.form.user.post,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.user, "post", $$v)
                              },
                              expression: "form.user.post",
                            },
                          }),
                          _c("form-row-edit-checkbox", {
                            attrs: { label: "Используется МЧД" },
                            on: { input: _vm.signsWithPoaChanged },
                            model: {
                              value: _vm.form.user.signs_with_poa,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.user, "signs_with_poa", $$v)
                              },
                              expression: "form.user.signs_with_poa",
                            },
                          }),
                          _vm.form.user.signs_with_poa
                            ? _c("form-row-upload-poa", {
                                attrs: {
                                  label: "МЧД",
                                  v: _vm.$v.form.poa,
                                  "organization-inn": _vm.form.organization.inn,
                                  "user-inn": _vm.form.user.inn,
                                  "user-snils": _vm.form.user.snils,
                                },
                                model: {
                                  value: _vm.form.poa,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "poa", $$v)
                                  },
                                  expression: "form.poa",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        [
                          _c("text-header", [
                            _vm._v("Завершение создания заявления"),
                          ]),
                          _c("registration-confirmation-text-block"),
                          _c(
                            "b-form-group",
                            { attrs: { state: !_vm.$v.form.check_rules } },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { state: !_vm.$v.form.check_rules },
                                  model: {
                                    value: _vm.form.check_rules,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "check_rules", $$v)
                                    },
                                    expression: "form.check_rules",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "grey-color fw-600" },
                                    [
                                      _vm._v(
                                        "Подтверждаю перечисленные выше условия"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "my-4" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "fs-20 text-danger font-weight-bold",
                              },
                              [
                                _vm._v(
                                  " Для возможности участия в закупочных сессиях Вам необходимо "
                                ),
                                _c(
                                  "a",
                                  {
                                    staticStyle: {
                                      "text-decoration": "underline",
                                      cursor: "pointer",
                                    },
                                    attrs: {
                                      href: _vm.links.add_money,
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v("пополнить лицевой счет")]
                                ),
                                _vm._v(
                                  " после прохождения процедуры регистрации. "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "my-5" },
                    [
                      _vm.tabIndex > 0
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mx-2 text-uppercase",
                              attrs: { variant: "custom-outline-secondary" },
                              on: { click: _vm.previousTab },
                            },
                            [_vm._v("Назад")]
                          )
                        : _vm._e(),
                      _vm.tabIndex < 2
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mx-2 text-uppercase",
                              attrs: { variant: "custom-green" },
                              on: { click: _vm.nextTab },
                            },
                            [_vm._v("Далее")]
                          )
                        : _vm._e(),
                      _vm.tabIndex === 2
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mx-2 text-uppercase",
                              attrs: {
                                disabled: _vm.$v.form.$invalid,
                                variant: "custom-green",
                              },
                              on: { click: _vm.makeXml },
                            },
                            [_vm._v("Готово")]
                          )
                        : _vm._e(),
                      _vm.tabIndex === 2 && _vm.$v.form.$invalid
                        ? _c("span", { staticClass: "text-danger mx-2" }, [
                            _vm._v(
                              "Заполните все обязательные поля на каждой вкладке."
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "select-organization",
          attrs: {
            centered: "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            title: "Укажите ИНН организации",
          },
          model: {
            value: _vm.showOrgModal,
            callback: function ($$v) {
              _vm.showOrgModal = $$v
            },
            expression: "showOrgModal",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-block text-left" },
            [
              _c("form-row-edit-text", {
                attrs: { label: "ИНН" },
                model: {
                  value: _vm.inn,
                  callback: function ($$v) {
                    _vm.inn = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "inn",
                },
              }),
              _vm.foundOrganizationBranches || _vm.kpp
                ? _c("form-row-edit-text", {
                    attrs: { label: "КПП" },
                    model: {
                      value: _vm.kpp,
                      callback: function ($$v) {
                        _vm.kpp = typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "kpp",
                    },
                  })
                : _vm._e(),
              _vm.foundOrganizationBranches || _vm.kpp
                ? _c("form-row-edit-text", {
                    attrs: { label: "КПП" },
                    model: {
                      value: _vm.kpp,
                      callback: function ($$v) {
                        _vm.kpp = $$v
                      },
                      expression: "kpp",
                    },
                  })
                : _vm._e(),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "mt-3", attrs: { md: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase float-right",
                          attrs: {
                            disabled: _vm.isFind,
                            variant: "custom-green",
                          },
                          on: { click: _vm.getOrganizationInformation },
                        },
                        [
                          _vm.isFind
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _vm._e(),
                          _vm._v(" Найти "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              !!_vm.tmpOrganization
                ? _c(
                    "b-row",
                    { staticClass: "py-3" },
                    [
                      _c("b-col", { staticClass: "fs-16 grey-color" }, [
                        _vm._v(" Это ваша организация ?"),
                      ]),
                      _c("b-col", { staticClass: "fs-16 grey-color fw-600" }, [
                        _vm._v(
                          " " + _vm._s(_vm.tmpOrganization.full_name) + " "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-2 text-uppercase",
                  attrs: { variant: "custom-outline-secondary" },
                  on: { click: _vm.cancelCertificate },
                },
                [_vm._v("Отмена")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase",
                  attrs: {
                    disabled: _vm.tmpOrganization == null,
                    variant: "custom-green",
                  },
                  on: { click: _vm.showCreateForm },
                },
                [_vm._v("Подтвердить")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "select-cert-modal",
          attrs: {
            centered: "",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            title: "Выберите сертификат",
          },
          model: {
            value: _vm.showCertificateSelectModal,
            callback: function ($$v) {
              _vm.showCertificateSelectModal = $$v
            },
            expression: "showCertificateSelectModal",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-block text-left" },
            [
              _c(
                "multiselect",
                {
                  attrs: {
                    "allow-clear": false,
                    "allow-empty": false,
                    "close-on-select": true,
                    multiple: false,
                    options: _vm.certificates,
                    "preserve-search": false,
                    "show-labels": false,
                    label: "subjectName",
                    placeholder: "Выберите сертификат",
                    "track-by": "thumbprint",
                  },
                  on: { input: _vm.setSelectedCertificateOwnerInfo },
                  scopedSlots: _vm._u([
                    {
                      key: "singleLabel",
                      fn: function (props) {
                        return [
                          _c("strong", [_vm._v("Субъект:")]),
                          _vm._v(" " + _vm._s(props.option.subjectName)),
                          _c("br"),
                          _c("br"),
                          _c("strong", [_vm._v("Срок действия:")]),
                          _vm._v(
                            " с " +
                              _vm._s(
                                _vm.getDateFormat(props.option.validFrom)
                              ) +
                              " по " +
                              _vm._s(_vm.getDateFormat(props.option.validTo)) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "option",
                      fn: function (props) {
                        return [
                          _c("strong", [_vm._v("Субъект:")]),
                          _vm._v(" " + _vm._s(props.option.subjectName)),
                          _c("br"),
                          _c("br"),
                          _c("strong", [_vm._v("Срок действия:")]),
                          _vm._v(
                            " с " +
                              _vm._s(
                                _vm.getDateFormat(props.option.validFrom)
                              ) +
                              " по " +
                              _vm._s(_vm.getDateFormat(props.option.validTo)) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.selectedCertificate,
                    callback: function ($$v) {
                      _vm.selectedCertificate = $$v
                    },
                    expression: "selectedCertificate",
                  },
                },
                [
                  _c(
                    "span",
                    { attrs: { slot: "noOptions" }, slot: "noOptions" },
                    [_vm._v("Нет данных")]
                  ),
                  _c(
                    "span",
                    { attrs: { slot: "noResult" }, slot: "noResult" },
                    [_vm._v("По вашему запросу сертификаты не найдены")]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-2 text-uppercase",
                  attrs: { variant: "custom-outline-secondary" },
                  on: { click: _vm.cancelCertificate },
                },
                [_vm._v("Отмена")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase",
                  attrs: {
                    disabled: !_vm.selectedCertificate,
                    variant: "custom-green",
                  },
                  on: { click: _vm.checkCertificate },
                },
                [_vm._v(" Продолжить")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "sign-form-modal",
          attrs: {
            centered: "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            scrollable: "",
            size: "lg",
            title: "Подписать заявление",
          },
          on: { close: _vm.cancelSignModal },
          model: {
            value: _vm.showSignModal,
            callback: function ($$v) {
              _vm.showSignModal = $$v
            },
            expression: "showSignModal",
          },
        },
        [
          _c("div", { staticClass: "d-block text-left" }, [
            _c(
              "div",
              { staticClass: "d-block text-left" },
              [
                _c(
                  "b-tabs",
                  { attrs: { "content-class": "mt-3" } },
                  [
                    _c(
                      "b-tab",
                      { attrs: { active: "" } },
                      [
                        _c("template", { slot: "title" }, [
                          _c(
                            "span",
                            { staticClass: "system_link-style fs-16" },
                            [_vm._v("Форма")]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "px-3" },
                          [
                            _c("text-header", [
                              _vm._v("Регистрационные данные организации"),
                            ]),
                            _c("text-row", {
                              attrs: {
                                value: _vm.form.organization.full_name,
                                label: "Сокращенное наименование",
                              },
                            }),
                            _c("text-row", {
                              attrs: {
                                value: _vm.form.organization.short_name,
                                label: "Сокращенное наименование",
                              },
                            }),
                            _c("text-row", {
                              attrs: {
                                value: _vm.form.organization.manager,
                                label: "Руководитель",
                              },
                            }),
                            _c("text-row", {
                              attrs: {
                                value: _vm.form.organization.inn,
                                label: "ИНН",
                              },
                            }),
                            _vm.selectedCertificateType === "legal"
                              ? _c("text-row", {
                                  attrs: {
                                    value: _vm.form.organization.kpp,
                                    label: "КПП",
                                  },
                                })
                              : _vm._e(),
                            _vm.selectedCertificateType === "legal"
                              ? _c("text-row", {
                                  attrs: {
                                    value: _vm.form.organization.ogrn,
                                    label: "ОГРН",
                                  },
                                })
                              : _vm._e(),
                            _vm.selectedCertificateType === "individual"
                              ? _c("text-row", {
                                  attrs: {
                                    value: _vm.form.organization.ogrn,
                                    label: "ОГРНИП",
                                  },
                                })
                              : _vm._e(),
                            _c("text-header", [
                              _vm._v("Контактные данные организации"),
                            ]),
                            _c("text-row", {
                              attrs: {
                                value: _vm.form.organization.post_address,
                                label: "Адрес почтовый",
                              },
                            }),
                            _c("text-row", {
                              attrs: {
                                value: _vm.form.organization.fact_address,
                                label: "Адрес фактический",
                              },
                            }),
                            _c("text-row", {
                              attrs: {
                                value: _vm.form.organization.email,
                                label: "Адрес электронной почты",
                              },
                            }),
                            _c("text-row-phone", {
                              attrs: {
                                value: _vm.form.organization.phone,
                                label: "Телефон",
                              },
                            }),
                            _c("text-row", {
                              attrs: {
                                value: _vm.form.organization.site,
                                label: "Адрес сайта",
                              },
                            }),
                            _c("text-row", {
                              attrs: {
                                value: _vm.form.organization.fax,
                                label: "Факс",
                              },
                            }),
                            _c("text-row", {
                              attrs: {
                                value: _vm.form.organization.contact_name,
                                label: "ФИО контактного лица",
                              },
                            }),
                            _c("text-header", [
                              _vm._v("Идентификационные данные пользователя"),
                            ]),
                            _c("text-row", {
                              attrs: {
                                value: _vm.form.user.name,
                                label: "ФИО",
                              },
                            }),
                            _c("text-row", {
                              attrs: { value: _vm.form.user.inn, label: "ИНН" },
                            }),
                            _c("text-row", {
                              attrs: {
                                value: _vm.form.user.snils,
                                label: "СНИЛС",
                              },
                            }),
                            _c("text-row", {
                              attrs: {
                                value: _vm.form.user.email,
                                label: "Адрес электронной почты",
                              },
                            }),
                            _c("text-row-phone", {
                              attrs: {
                                value: _vm.form.user.phone,
                                label: "Телефон",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c(
                      "b-tab",
                      [
                        _c("template", { slot: "title" }, [
                          _c(
                            "span",
                            { staticClass: "system_link-style fs-16" },
                            [_vm._v("Xml версия")]
                          ),
                        ]),
                        _c(
                          "pre",
                          { staticClass: "fs-10 grey-color p-3 greyBg m-0" },
                          [_vm._v(_vm._s(_vm.signedFormXml))]
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c("text-row", {
                attrs: {
                  value: _vm.selectedCertificate?.subjectName,
                  label: "Сертификат",
                },
              }),
              _c(
                "b-button",
                {
                  staticClass: "mr-2 text-uppercase",
                  attrs: { variant: "custom-outline-secondary" },
                  on: { click: _vm.cancelSignModal },
                },
                [_vm._v("Отмена")]
              ),
              !_vm.isSignedXml
                ? _c(
                    "b-overlay",
                    {
                      staticClass: "d-inline-block",
                      attrs: {
                        show: _vm.isSigningXml,
                        opacity: "0.6",
                        rounded: "",
                        "spinner-small": "",
                        "spinner-variant": "primary",
                      },
                    },
                    [
                      !_vm.isSignedXml
                        ? _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase",
                              attrs: {
                                disabled: !_vm.selectedCertificate,
                                variant: "custom-green",
                              },
                              on: { click: _vm.signForm },
                            },
                            [_vm._v("Подписать")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isSignedXml
                ? _c(
                    "b-overlay",
                    {
                      staticClass: "d-inline-block",
                      attrs: {
                        show: _vm.isDataSending,
                        opacity: "0.6",
                        rounded: "",
                        "spinner-small": "",
                        "spinner-variant": "primary",
                      },
                    },
                    [
                      _vm.isSignedXml
                        ? _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase",
                              attrs: {
                                disabled: !_vm.selectedCertificate,
                                variant: "custom-green",
                              },
                              on: { click: _vm.register },
                            },
                            [_vm._v("Зарегистрироваться")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }