var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "create" },
    [
      _vm.loading ? _c("system-preloader") : _vm._e(),
      _c("form-header", {
        attrs: { title: "Запрос коммерческих предложений" },
      }),
      _c(
        "b-container",
        { staticClass: "greyBg pb-4", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c("text-header", [_vm._v("Информация о заказчике")]),
              _c("text-row-link", {
                attrs: {
                  "router-link": {
                    name: "OrganizationShow",
                    params: { id: _vm.$store.state.organization.id },
                  },
                  value: _vm.$store.state.organization.name,
                  compact: "",
                  label: "Наименование",
                },
              }),
              _c("text-row", {
                attrs: {
                  value: _vm.$store.state.organization.inn,
                  compact: "",
                  label: "ИНН",
                },
              }),
              _c("text-row", {
                attrs: {
                  value: _vm.$store.state.organization.kpp,
                  compact: "",
                  label: "КПП",
                },
              }),
              _c("text-row", {
                attrs: {
                  value: _vm.$store.state.organization.ogrn,
                  compact: "",
                  label: "ОГРН",
                },
              }),
              _c("text-row", {
                attrs: {
                  value: _vm.$store.state.organization.address,
                  compact: "",
                  label: "Адрес",
                },
              }),
              _c(
                "text-row-raw",
                { attrs: { compact: "", label: "Адрес электронной почты" } },
                [
                  _c(
                    "a",
                    {
                      staticClass: "fs-14 col-form-label d-inline-block",
                      attrs: {
                        href: "mailto:" + _vm.$store.state.organization.email,
                      },
                    },
                    [_vm._v(_vm._s(_vm.$store.state.organization.email))]
                  ),
                ]
              ),
              _c(
                "text-row-raw",
                { attrs: { compact: "", label: "Контактный номер телефона" } },
                [
                  _c(
                    "a",
                    {
                      staticClass: "fs-14 col-form-label d-inline-block",
                      attrs: {
                        href: "tel:+" + _vm.$store.state.organization.phone,
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$parsePhone(_vm.$store.state.organization.phone)
                        )
                      ),
                    ]
                  ),
                ]
              ),
              _c("text-header", [_vm._v("Информация о запросе КП")]),
              _c("form-row-single-select", {
                attrs: {
                  options: _vm.purchaseCategories,
                  v: _vm.$v.form.purchase_category_id,
                  label: "Предмет закупки",
                  placeholder: "Выберите предмет закупки",
                },
                model: {
                  value: _vm.form.purchase_category_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "purchase_category_id", $$v)
                  },
                  expression: "form.purchase_category_id",
                },
              }),
              _c("form-row-edit-date", {
                attrs: {
                  "min-date": _vm.moment().format("YYYY-MM-DD"),
                  v: _vm.$v.form.deadline,
                  label: "Срок сбора КП",
                  placeholder: "Укажите срок сбора КП",
                },
                model: {
                  value: _vm.form.deadline,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deadline", $$v)
                  },
                  expression: "form.deadline",
                },
              }),
              _c("form-row-single-line-document-upload", {
                attrs: {
                  "label-cols-lg": 3,
                  v: _vm.$v.form.document,
                  title: "Техническое задание",
                },
                model: {
                  value: _vm.form.document,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "document", $$v)
                  },
                  expression: "form.document",
                },
              }),
              _c("form-row-specification-table", {
                attrs: {
                  "spec-error-message": _vm.specErrorMessage,
                  "spec-errors": _vm.specErrors,
                  v: _vm.$v.form.specs,
                  "with-count-edit": "",
                },
                model: {
                  value: _vm.form.specs,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "specs", $$v)
                  },
                  expression: "form.specs",
                },
              }),
              _c("text-header", [_vm._v("Приглашение поставщиков")]),
              _c(
                "b-form-group",
                {
                  staticClass: "w-100",
                  attrs: {
                    state: !_vm.$v.form.emails.$invalid,
                    label:
                      "Выберите или введите почтовые ящики поставщиков, на них будут отправлены приглашения к закупке",
                    "label-class":
                      "fs-14 grey-color fw-700 d-flex align-items-baseline",
                    "label-cols-lg": "3",
                    "label-cols-sm": "4",
                    "label-for": "org-select",
                  },
                },
                [
                  _c(
                    "multiselect",
                    {
                      class: {
                        "is-valid": !_vm.$v.form.emails.$invalid,
                        "is-invalid": _vm.$v.form.emails.$invalid,
                      },
                      attrs: {
                        id: "org-select",
                        "close-on-select": false,
                        "clear-on-select": false,
                        "internal-search": false,
                        loading: _vm.isLoading,
                        multiple: true,
                        options: _vm.organizations,
                        "options-limit": 100,
                        "preserve-search": false,
                        taggable: true,
                        "deselect-label": "Отменить",
                        label: "name",
                        placeholder:
                          "Выберите организации / укажите адреса электронной почты",
                        "select-label": "Выбрать",
                        "selected-label": "Выбрано",
                        "tag-placeholder": "Добавить",
                        "track-by": "id",
                      },
                      on: { tag: _vm.addTag, "search-change": _vm.asyncFind },
                      model: {
                        value: _vm.form.emails,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "emails", $$v)
                        },
                        expression: "form.emails",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "noOptions" }, slot: "noOptions" },
                        [_vm._v("Нет данных.")]
                      ),
                      _c(
                        "span",
                        { attrs: { slot: "noResult" }, slot: "noResult" },
                        [
                          _vm._v(
                            "Записей, соответствующих вашему запросу, не найдено."
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm.$v.form.emails.$invalid
                    ? [
                        _c(
                          "b-form-invalid-feedback",
                          { attrs: { "force-show": "" } },
                          [_vm._v("Поле обязательно для заполнения")]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "b-row",
                { staticClass: "my-3" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-4 text-uppercase",
                          attrs: { variant: "custom-outline-secondary" },
                          on: { click: _vm.onCancel },
                        },
                        [_vm._v("Отменить")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            disabled:
                              _vm.$v.form.$invalid ||
                              !_vm.$store.getters.getCanSign,
                            variant: "custom-green",
                          },
                          on: { click: _vm.prepareForm },
                        },
                        [_vm._v("Подписать и разместить запрос")]
                      ),
                      !_vm.$store.getters.getCanSign
                        ? _c("span", { staticClass: "text-danger pt-1" }, [
                            _vm._v(
                              "Подписание возможно только при авторизации при помощи ЭЦП"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "sign-form-modal",
          attrs: {
            centered: "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            scrollable: "",
            size: "lg",
            title: "Подписать заявку",
          },
          on: { close: _vm.cancelSignModal },
          model: {
            value: _vm.showSignModal,
            callback: function ($$v) {
              _vm.showSignModal = $$v
            },
            expression: "showSignModal",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-block text-left" },
            [
              _c(
                "b-tabs",
                { attrs: { "content-class": "mt-3 px-3" } },
                [
                  _c(
                    "b-tab",
                    {
                      attrs: { active: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                { staticClass: "system_link-style fs-16" },
                                [_vm._v("Форма")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("h4", { staticClass: "font-weight-bold" }, [
                        _vm._v("Информация о заказчике"),
                      ]),
                      _c("text-row", {
                        attrs: {
                          value: _vm.$store.state.organization.full_name,
                          compact: "",
                          label: "Наименование организации",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          value: _vm.$store.state.organization.inn,
                          compact: "",
                          label: "ИНН",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          value: _vm.$store.state.organization.kpp,
                          compact: "",
                          label: "КПП",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          value: _vm.$store.state.organization.post_address,
                          compact: "",
                          label: "Адрес",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          value: _vm.$store.state.organization.email,
                          compact: "",
                          label: "Email",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          value: _vm.$store.state.organization.phone,
                          compact: "",
                          label: "Номер контактного телефона",
                        },
                      }),
                      _c("h4", { staticClass: "font-weight-bold" }, [
                        _vm._v("Запрос КП"),
                      ]),
                      _c("text-row", {
                        attrs: {
                          value: _vm.purchaseCategoryName,
                          compact: "",
                          label: "Наименование запроса КП",
                        },
                      }),
                      _c("text-row-date", {
                        attrs: {
                          value: _vm.form.deadline,
                          compact: "",
                          label: "Дата окончания приема предложений",
                        },
                      }),
                      _c("text-row-single-document", {
                        attrs: {
                          value: _vm.form.document,
                          compact: "",
                          label: "Техническое задание",
                        },
                      }),
                      _c("h4", { staticClass: "font-weight-bold" }, [
                        _vm._v("Спецификация"),
                      ]),
                      _vm.form.specs.length > 0
                        ? _c("b-form-group", [
                            _c(
                              "ul",
                              _vm._l(_vm.form.specs, function (item, index) {
                                return _c(
                                  "li",
                                  {
                                    key: index,
                                    staticClass:
                                      "fs-14 grey-color my-auto col-form-label",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(item.name) +
                                        " - " +
                                        _vm._s(item.count) +
                                        ", " +
                                        _vm._s(item.okei.text)
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                { staticClass: "system_link-style fs-16" },
                                [_vm._v("Xml представление")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      !_vm.isSignedXml
                        ? _c("b-form-textarea", {
                            staticClass: "text-monospace fs-12",
                            attrs: {
                              disabled: "",
                              "no-auto-shrink": "",
                              "no-resize": "",
                              rows: "20",
                              wrap: "off",
                            },
                            model: {
                              value: _vm.formXml,
                              callback: function ($$v) {
                                _vm.formXml = $$v
                              },
                              expression: "formXml",
                            },
                          })
                        : _vm._e(),
                      _vm.isSignedXml
                        ? _c("b-form-textarea", {
                            staticClass: "text-monospace fs-12",
                            attrs: {
                              disabled: "",
                              "no-auto-shrink": "",
                              "no-resize": "",
                              rows: "20",
                              wrap: "off",
                            },
                            model: {
                              value: _vm.signedFormXml,
                              callback: function ($$v) {
                                _vm.signedFormXml = $$v
                              },
                              expression: "signedFormXml",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "w-100 px-3",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c("text-row", {
                attrs: {
                  value: _vm.certificate?.subjectName,
                  label: "Сертификат",
                },
              }),
              _c(
                "b-button",
                {
                  staticClass: "mr-2 text-uppercase",
                  attrs: {
                    disabled: _vm.isDataSending || _vm.isSigningXml,
                    variant: "custom-outline-secondary",
                  },
                  on: { click: _vm.cancelSignModal },
                },
                [_vm._v("Отмена")]
              ),
              !_vm.isSignedXml
                ? _c(
                    "b-overlay",
                    {
                      staticClass: "d-inline-block",
                      attrs: {
                        show: _vm.isSigningXml,
                        opacity: "0.6",
                        rounded: "",
                        "spinner-small": "",
                        "spinner-variant": "primary",
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            disabled: !_vm.certificate,
                            variant: "custom-green",
                          },
                          on: { click: _vm.signForm },
                        },
                        [_vm._v("Подписать")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isSignedXml
                ? _c(
                    "b-overlay",
                    {
                      staticClass: "d-inline-block",
                      attrs: {
                        show: _vm.isDataSending,
                        opacity: "0.6",
                        rounded: "",
                        "spinner-small": "",
                        "spinner-variant": "primary",
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            disabled: !_vm.certificate,
                            variant: "custom-green",
                          },
                          on: { click: _vm.sendData },
                        },
                        [_vm._v("Отправить")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }