<template>
    <div class="create">
        <system-preloader v-if="loading"></system-preloader>
        <form-header title="Запрос коммерческих предложений" />
        <b-container class="greyBg pb-4" fluid>
            <b-container>
                <text-header>Информация о заказчике</text-header>
                <text-row-link :router-link="{ name: 'OrganizationShow', params: { id: $store.state.organization.id } }" :value="$store.state.organization.name" compact label="Наименование" />
                <text-row :value="$store.state.organization.inn" compact label="ИНН" />
                <text-row :value="$store.state.organization.kpp" compact label="КПП" />
                <text-row :value="$store.state.organization.ogrn" compact label="ОГРН" />
                <text-row :value="$store.state.organization.address" compact label="Адрес" />
                <text-row-raw compact label="Адрес электронной почты">
                    <a :href="'mailto:' + $store.state.organization.email" class="fs-14 col-form-label d-inline-block">{{ $store.state.organization.email }}</a>
                </text-row-raw>
                <text-row-raw compact label="Контактный номер телефона">
                    <a :href="'tel:+' + $store.state.organization.phone" class="fs-14 col-form-label d-inline-block">{{ $parsePhone($store.state.organization.phone) }}</a>
                </text-row-raw>

                <text-header>Информация о запросе КП</text-header>
                <form-row-single-select v-model="form.purchase_category_id" :options="purchaseCategories" :v="$v.form.purchase_category_id" label="Предмет закупки" placeholder="Выберите предмет закупки" />
                <form-row-edit-date v-model="form.deadline" :min-date="moment().format('YYYY-MM-DD')" :v="$v.form.deadline" label="Срок сбора КП" placeholder="Укажите срок сбора КП" />
                <form-row-single-line-document-upload v-model="form.document" :label-cols-lg="3" :v="$v.form.document" title="Техническое задание" />

                <form-row-specification-table v-model="form.specs" :spec-error-message="specErrorMessage" :spec-errors="specErrors" :v="$v.form.specs" with-count-edit />
                <text-header>Приглашение поставщиков</text-header>
                <b-form-group
                    :state="!$v.form.emails.$invalid"
                    class="w-100"
                    label="Выберите или введите почтовые ящики поставщиков, на них будут отправлены приглашения к закупке"
                    label-class="fs-14 grey-color fw-700 d-flex align-items-baseline"
                    label-cols-lg="3"
                    label-cols-sm="4"
                    label-for="org-select">
                    <multiselect
                        id="org-select"
                        v-model="form.emails"
                        :class="{ 'is-valid': !$v.form.emails.$invalid, 'is-invalid': $v.form.emails.$invalid }"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :internal-search="false"
                        :loading="isLoading"
                        :multiple="true"
                        :options="organizations"
                        :options-limit="100"
                        :preserve-search="false"
                        :taggable="true"
                        deselect-label="Отменить"
                        label="name"
                        placeholder="Выберите организации / укажите адреса электронной почты"
                        select-label="Выбрать"
                        selected-label="Выбрано"
                        tag-placeholder="Добавить"
                        track-by="id"
                        @tag="addTag"
                        @search-change="asyncFind">
                        <span slot="noOptions">Нет данных.</span>
                        <span slot="noResult">Записей, соответствующих вашему запросу, не найдено.</span>
                    </multiselect>
                    <template v-if="$v.form.emails.$invalid">
                        <b-form-invalid-feedback force-show>Поле обязательно для заполнения</b-form-invalid-feedback>
                    </template>
                </b-form-group>
                <b-row class="my-3">
                    <b-col>
                        <b-button class="mr-4 text-uppercase" variant="custom-outline-secondary" @click="onCancel">Отменить</b-button>
                        <b-button :disabled="$v.form.$invalid || !$store.getters.getCanSign" class="text-uppercase" variant="custom-green" @click="prepareForm">Подписать и разместить запрос</b-button>
                        <span v-if="!$store.getters.getCanSign" class="text-danger pt-1">Подписание возможно только при авторизации при помощи ЭЦП</span>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-modal ref="sign-form-modal" v-model="showSignModal" centered no-close-on-backdrop no-close-on-esc scrollable size="lg" title="Подписать заявку" @close="cancelSignModal">
            <div class="d-block text-left">
                <b-tabs content-class="mt-3 px-3">
                    <b-tab active>
                        <template #title>
                            <span class="system_link-style fs-16">Форма</span>
                        </template>
                        <h4 class="font-weight-bold">Информация о заказчике</h4>
                        <text-row :value="$store.state.organization.full_name" compact label="Наименование организации" />
                        <text-row :value="$store.state.organization.inn" compact label="ИНН" />
                        <text-row :value="$store.state.organization.kpp" compact label="КПП" />
                        <text-row :value="$store.state.organization.post_address" compact label="Адрес" />
                        <text-row :value="$store.state.organization.email" compact label="Email" />
                        <text-row :value="$store.state.organization.phone" compact label="Номер контактного телефона" />

                        <h4 class="font-weight-bold">Запрос КП</h4>
                        <text-row :value="purchaseCategoryName" compact label="Наименование запроса КП" />
                        <text-row-date :value="form.deadline" compact label="Дата окончания приема предложений" />
                        <text-row-single-document :value="form.document" compact label="Техническое задание" />

                        <h4 class="font-weight-bold">Спецификация</h4>
                        <b-form-group v-if="form.specs.length > 0">
                            <ul>
                                <li v-for="(item, index) in form.specs" :key="index" class="fs-14 grey-color my-auto col-form-label">{{ item.name }} - {{ item.count }}, {{ item.okei.text }}</li>
                            </ul>
                        </b-form-group>
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <span class="system_link-style fs-16">Xml представление</span>
                        </template>
                        <b-form-textarea v-if="!isSignedXml" v-model="formXml" class="text-monospace fs-12" disabled no-auto-shrink no-resize rows="20" wrap="off"></b-form-textarea>
                        <b-form-textarea v-if="isSignedXml" v-model="signedFormXml" class="text-monospace fs-12" disabled no-auto-shrink no-resize rows="20" wrap="off"></b-form-textarea>
                    </b-tab>
                </b-tabs>
            </div>
            <div slot="modal-footer" class="w-100 px-3">
                <text-row :value="certificate?.subjectName" label="Сертификат" />
                <b-button :disabled="isDataSending || isSigningXml" class="mr-2 text-uppercase" variant="custom-outline-secondary" @click="cancelSignModal">Отмена</b-button>
                <b-overlay v-if="!isSignedXml" :show="isSigningXml" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button :disabled="!certificate" class="text-uppercase" variant="custom-green" @click="signForm">Подписать</b-button>
                </b-overlay>
                <b-overlay v-if="isSignedXml" :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button :disabled="!certificate" class="text-uppercase" variant="custom-green" @click="sendData">Отправить</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import FormRowSpecificationTable from '@/components/common/form-rows/form-row-specification-table';
import FormHeader from '@/components/common/form-rows/form-header';
import TextRowRaw from '@/components/common/form-rows/text-row-raw.vue';
import { getCertificate } from 'crypto-pro';
import debounceMixin from '@/mixins/debounce';
import moment from 'moment-timezone';
import TextRowSingleDocument from '@/components/common/form-rows/text-row-single-document.vue';
import { $api } from '@/services/backend/api';

export default {
    name: 'QuotationRequestCreate',
    metaInfo() {
        return {
            title: 'Создание запроса коммерческих предложений'
        };
    },
    components: {
        TextRowSingleDocument,
        TextRowRaw,
        FormHeader,
        FormRowSpecificationTable
    },
    mixins: [debounceMixin],
    data() {
        return {
            visible: [],
            ktruSelectModalShown: false,
            selectedKtruItemId: null,
            selectedKtruItem: null,
            ktruTableColumns: [
                { name: 'id', sortField: 'id', title: 'ИД', width: '100px' },
                { name: 'reg_number', sortField: 'reg_number', title: 'Рег.номер', width: '150px' },
                { name: 'name', sortField: 'name', title: 'Название', width: '400px' },
                { name: 'specs', title: 'Спецификация' },
                { name: 'actions', title: 'Действия', width: '100px' }
            ],
            sortOrder: [{ field: 'id', direction: 'asc' }],
            ktruTableFilter: {
                name: '',
                reg_number: '',
                specs: ''
            },
            okeis: [],
            loading: true,
            form: {
                // name: '',
                deadline: null,
                document: null,
                specs: [],
                emails: [],
                purchase_category_id: null
            },
            spec: {
                number: 1,
                order: 1,
                name: '',
                count: 1,
                okei: null,
                ktru_item_id: null,
                ktru: null
            },
            showSpec: false,
            organizations: [],
            emails: [],
            tags: [],
            isLoading: false,
            formXml: '',
            signedFormXml: null,
            isSigningXml: false,
            isDataSending: false,
            isSignedXml: false,
            showSignModal: false,
            purchaseCategories: [],
            certificate: null
        };
    },
    validations: {
        form: {
            purchase_category_id: { required },
            deadline: { required },
            document: { required },
            specs: { required },
            emails: { required }
        }
    },
    async mounted() {
        if (!this.$store.getters.isLoggedIn) {
            await this.$router.replace({ name: '404' }).catch(() => {});
        } else {
            await this.fillData();
        }
    },
    methods: {
        moment,
        async fillData() {
            this.purchaseCategories = await this.$globalDictionaries.deliverableGroups;
            this.loading = false;
        },
        async asyncFind(query) {
            this.debounce(async () => {
                this.isLoading = true;
                query ? (this.organizations = await this.$api.organizations.search({ query })) : (this.organizations = []);
                this.organizations.push(...this.tags);
                this.isLoading = false;
            });
        },
        onCancel() {
            this.$router.push({ name: 'QuotationRequestsRequestsList', params: { cat: 'all' } }).catch(() => {});
        },
        addTag(newTag) {
            const tag = {
                email: newTag,
                id: newTag,
                name: newTag
            };
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(tag.email)) {
                this.form.emails.push(tag);
                this.organizations.push(tag);
                this.tags.push(tag);
            }
        },
        cancelSignModal() {
            this.isSignedXml = false;
            this.showSignModal = false;
        },
        async prepareForm() {
            this.certificate = await getCertificate(this.$store.getters.getCurrentThumbprint);
            if (this.certificate) {
                await this.showSignForm();
            }
        },
        async showSignForm() {
            this.formXml = await this.$api.quotationRequests.getRequestXml(this.form);
            if (this.formXml) this.showSignModal = true;
        },
        async signForm() {
            this.isSigningXml = true;
            this.signedFormXml = await $api.signXML(this.formXml);
            this.isSignedXml = !!this.signedFormXml;
            this.isSigningXml = false;
        },
        async sendData() {
            this.isDataSending = true;
            if (this.certificate) {
                const formData = {
                    form: this.form,
                    xml: this.signedFormXml,
                    certificate: this.certificate
                };
                if (await this.$api.quotationRequests.storeSignedRequest(formData)) {
                    this.cancelSignModal();
                    this.onCancel();
                }
            }
            this.isDataSending = false;
        }
    },
    computed: {
        specErrors() {
            return !!this.specErrorMessage;
        },
        specErrorMessage() {
            if (this.form.specs.length === 0) return 'Заполните спецификацию';
            if (!this.form.specs.every((e) => e.count > 0)) return 'У одной или нескольких позиций в таблице спецификации не заполнено поле "Количество"';
            return '';
        },
        purchaseCategoryName() {
            return this.form.purchase_category_id ? this.purchaseCategories.find((e) => e.id === this.form.purchase_category_id).name : '';
        }
    }
};
</script>

<style scoped>
a {
    color: var(--green-color) !important;
}

.table {
    border-collapse: collapse;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>
