var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "div",
        {
          staticClass: "purchases_create atmo-page atmo-hash-jump-point",
          attrs: { id: "atmo-top" },
        },
        [
          _c(
            "b-container",
            {
              staticClass: "purchase-offer-header-block",
              attrs: { fluid: "" },
            },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          { staticClass: "fs-40 fw-600 white-color mt-3 mb-3" },
                          [
                            _vm._v(
                              "Подача предложения на коммерческую закупку "
                            ),
                            _c("br"),
                            _vm._v("№ " + _vm._s(_vm.purchase.reg_number)),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "white-color fs-14" }, [
                          _vm._v("Форма подачи предложения"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "my-2", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-card",
                    { staticClass: "my-3", attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        {
                          staticClass: "p-1",
                          attrs: { "header-tag": "header", role: "tab" },
                          on: {
                            click: function ($event) {
                              _vm.purchaseInfoDisplayed =
                                !_vm.purchaseInfoDisplayed
                            },
                          },
                        },
                        [
                          _c(
                            "h2",
                            {
                              staticClass:
                                "grey-color font-weight-bold my-2 ml-3 cursor-pointer",
                            },
                            [
                              _vm._v(" Информация об извещении "),
                              _vm.purchaseInfoDisplayed
                                ? [
                                    _c(
                                      "span",
                                      { staticClass: "float-right mr-3" },
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass: "icon alt",
                                          attrs: {
                                            icon: ["fas", "chevron-up"],
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : [
                                    _c(
                                      "span",
                                      { staticClass: "float-right mr-3" },
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass: "icon alt",
                                          attrs: {
                                            icon: ["fas", "chevron-down"],
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ]
                      ),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "accordion-purchase-info",
                            accordion: "my-accordion",
                            role: "tabpanel",
                          },
                          model: {
                            value: _vm.purchaseInfoDisplayed,
                            callback: function ($$v) {
                              _vm.purchaseInfoDisplayed = $$v
                            },
                            expression: "purchaseInfoDisplayed",
                          },
                        },
                        [
                          _c(
                            "b-card-body",
                            [
                              _c("text-header", [
                                _vm._v("Информация о заказчике"),
                              ]),
                              _c("text-row-link", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  "router-link": {
                                    name: "OrganizationShow",
                                    params: { id: _vm.purchase.customer.id },
                                  },
                                  label: "Наименование",
                                },
                                model: {
                                  value: _vm.purchase.customer.full_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.purchase.customer,
                                      "full_name",
                                      $$v
                                    )
                                  },
                                  expression: "purchase.customer.full_name",
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  label: "ИНН",
                                },
                                model: {
                                  value: _vm.purchase.customer.inn,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.purchase.customer, "inn", $$v)
                                  },
                                  expression: "purchase.customer.inn",
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  label: "КПП",
                                },
                                model: {
                                  value: _vm.purchase.customer.kpp,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.purchase.customer, "kpp", $$v)
                                  },
                                  expression: "purchase.customer.kpp",
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  label: "ОГРН",
                                },
                                model: {
                                  value: _vm.purchase.customer.ogrn,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.purchase.customer, "ogrn", $$v)
                                  },
                                  expression: "purchase.customer.ogrn",
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  label: "Юридический адрес",
                                },
                                model: {
                                  value: _vm.purchase.customer.address,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.purchase.customer,
                                      "address",
                                      $$v
                                    )
                                  },
                                  expression: "purchase.customer.address",
                                },
                              }),
                              _vm.purchase.purchase_terms.contact_info
                                ? _c("text-row", {
                                    attrs: {
                                      compact: true,
                                      "label-cols-lg": 4,
                                      "label-cols-sm": 4,
                                      label:
                                        "Дополнительная контактная информация",
                                    },
                                    model: {
                                      value:
                                        _vm.purchase.purchase_terms
                                          .contact_info,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.purchase.purchase_terms,
                                          "contact_info",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "purchase.purchase_terms.contact_info",
                                    },
                                  })
                                : _vm._e(),
                              _c("text-header", [_vm._v("Условия закупки")]),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  value:
                                    _vm.purchase.purchase_terms.purchase_object,
                                  label: "Объект закупки",
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  value:
                                    _vm.purchase.purchase_terms
                                      .deliverable_group.name,
                                  label: "Предмет закупки",
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  value:
                                    _vm.purchase.purchase_terms.duration_type
                                      .name,
                                  label: "Тип закупочной сессии",
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  label:
                                    "Продолжительность закупочной сессии" +
                                    _vm.purchase.purchase_terms.duration_type
                                      .suffix,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  value: _vm.purchase.purchase_terms.duration,
                                },
                              }),
                              _c("text-row-datetime", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  label: "Дата и время размещения закупки",
                                },
                                model: {
                                  value:
                                    _vm.purchase.purchase_terms
                                      .purchase_start_datetime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.purchase.purchase_terms,
                                      "purchase_start_datetime",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "purchase.purchase_terms.purchase_start_datetime",
                                },
                              }),
                              _c("text-row-datetime", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  label:
                                    "Дата и время окончания приема предложений",
                                },
                                model: {
                                  value:
                                    _vm.purchase.purchase_terms
                                      .purchase_end_datetime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.purchase.purchase_terms,
                                      "purchase_end_datetime",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "purchase.purchase_terms.purchase_end_datetime",
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  value:
                                    _vm.purchase.purchase_terms.payment_form,
                                  label: "Вид оплаты",
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  value:
                                    _vm.purchase.purchase_terms.payment_term,
                                  label: "Условия оплаты",
                                },
                              }),
                              _c("text-row-date", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  value:
                                    _vm.purchase.purchase_terms
                                      .planned_contract_conclusion_datetime,
                                  label: "Планируемая дата заключения договора",
                                },
                              }),
                              _c("text-row-date", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  value:
                                    _vm.purchase.purchase_terms
                                      .planned_contract_execution_datetime,
                                  label: "Планируемая дата исполнения договора",
                                },
                              }),
                              _c("text-row-price", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  value:
                                    _vm.purchase.purchase_terms.start_price,
                                  label: "Сумма закупки",
                                },
                              }),
                              _c("text-row-single-document", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 4,
                                  "label-cols-sm": 4,
                                  value:
                                    _vm.purchase.purchase_terms
                                      .contract_draft_attachment,
                                  label: "Проект договора",
                                },
                              }),
                              _vm.purchase.additional_documents.length > 0
                                ? _c("text-row-documents", {
                                    attrs: {
                                      compact: true,
                                      "label-cols-lg": 4,
                                      "label-cols-sm": 4,
                                      label: "Дополнительные документы",
                                    },
                                    model: {
                                      value: _vm.purchase.additional_documents,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.purchase,
                                          "additional_documents",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "purchase.additional_documents",
                                    },
                                  })
                                : _vm._e(),
                              _c("text-header", [
                                _vm._v("Требования к поставщикам"),
                              ]),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 6,
                                  "label-cols-sm": 6,
                                  value: _vm.purchase.supplier_requirements
                                    .only_not_rnp
                                    ? "ДА"
                                    : "НЕТ",
                                  label:
                                    "Отсутствие в реестре недобросовестных поставщиков",
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 6,
                                  "label-cols-sm": 6,
                                  value: _vm.purchase.supplier_requirements
                                    .only_smp
                                    ? "ДА"
                                    : "НЕТ",
                                  label:
                                    "Участником закупки могут быть только субъекты малого предпринимательства (СМП)",
                                },
                              }),
                              _c("text-row", {
                                attrs: {
                                  compact: true,
                                  "label-cols-lg": 6,
                                  "label-cols-sm": 6,
                                  value: _vm.purchase.supplier_requirements
                                    .has_additional_requirements
                                    ? "ДА"
                                    : "НЕТ",
                                  label:
                                    "Дополнительные требования к участникам закупки",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.purchase.supplier_requirements
                                          .has_additional_requirements,
                                      expression:
                                        "purchase.supplier_requirements.has_additional_requirements",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "section",
                                    { staticStyle: { width: "100%" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "table-responsive" },
                                        [
                                          _c(
                                            "table",
                                            {
                                              staticClass:
                                                "atmo-content-table mb-0",
                                            },
                                            [
                                              _c("thead", [
                                                _c("tr", [
                                                  _c(
                                                    "th",
                                                    {
                                                      staticClass:
                                                        "atmo-ctc-width-45pct",
                                                    },
                                                    [_vm._v("Описание")]
                                                  ),
                                                  _c(
                                                    "th",
                                                    {
                                                      staticClass:
                                                        "atmo-ctc-width-45pct",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Причина или цель установки требования"
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]),
                                              _c(
                                                "tbody",
                                                _vm._l(
                                                  _vm.purchase
                                                    .supplier_requirements
                                                    .additional,
                                                  function (
                                                    requirement,
                                                    index
                                                  ) {
                                                    return _c(
                                                      "tr",
                                                      { key: index },
                                                      [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "atmo-ctc-width-45pct",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                requirement.description
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "atmo-ctc-width-45pct",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                requirement.reason
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.purchase.delivery_terms.addresses.length >
                                0 || _vm.purchase.delivery_terms.schedule
                                ? [
                                    _c("text-header", [
                                      _vm._v("Условия поставки"),
                                    ]),
                                    _vm.purchase.delivery_terms.addresses
                                      .length > 0
                                      ? _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              "label-cols-lg": 4,
                                              "label-cols-sm": 4,
                                              label:
                                                "Адреса для доставки/самовывоза:",
                                              "label-class":
                                                "fs-14 grey-color fw-700 d-flex align-items-baseline",
                                            },
                                          },
                                          [
                                            _c(
                                              "ul",
                                              _vm._l(
                                                _vm.purchase.delivery_terms
                                                  .addresses,
                                                function (address, index) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: index,
                                                      staticClass:
                                                        "fs-14 grey-color my-auto col-form-label",
                                                    },
                                                    [_vm._v(_vm._s(address))]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("text-row", {
                                      attrs: {
                                        compact: true,
                                        "label-cols-lg": 4,
                                        "label-cols-sm": 4,
                                        value:
                                          _vm.purchase.delivery_terms.schedule,
                                        label:
                                          "График поставки товаров (выполнения работ, оказания услуг)",
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("text-header", [_vm._v("Информация об организации")]),
                  _c("form-row-edit-text", {
                    attrs: {
                      v: _vm.$v.form.contact_name,
                      label: "Контактное лицо поставщика",
                    },
                    model: {
                      value: _vm.form.contact_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contact_name", $$v)
                      },
                      expression: "form.contact_name",
                    },
                  }),
                  _c("form-row-single-line-document-upload", {
                    attrs: {
                      "label-cols-lg": 3,
                      "label-cols-sm": 3,
                      v: _vm.$v.form.organization_card,
                      title: "Карточка предприятия",
                    },
                    model: {
                      value: _vm.form.organization_card,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "organization_card", $$v)
                      },
                      expression: "form.organization_card",
                    },
                  }),
                  _c("form-row-edit-checkbox", {
                    attrs: { label: "Организация освобождена от уплаты НДС" },
                    model: {
                      value: _vm.form.without_vat,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "without_vat", $$v)
                      },
                      expression: "form.without_vat",
                    },
                  }),
                  _c("text-header", [_vm._v("Спецификация")]),
                  _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { attrs: { width: "1" } }),
                          _c("th", [
                            _vm._v("Наименование товара/работы/услуги"),
                          ]),
                          _c("th", { attrs: { width: "110px" } }, [
                            _vm._v("Количество /"),
                            _c("br"),
                            _vm._v("Ед.изм"),
                          ]),
                          _c("th", { attrs: { width: "110px" } }, [
                            _vm._v("Извещение."),
                            _c("br"),
                            _vm._v("Цена за единицу /"),
                            _c("br"),
                            _vm._v("Стоимость"),
                          ]),
                          _c("th", { attrs: { width: "160px" } }, [
                            _vm._v("Цена за ед. с НДС, руб."),
                          ]),
                          _c("th", { attrs: { width: "175px" } }, [
                            _vm._v("НДС, %"),
                          ]),
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              attrs: { width: "140px" },
                            },
                            [_vm._v("Стоимость с НДС, руб.")]
                          ),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm._l(_vm.form.deliverables, function (item, index) {
                            return _c("fragment", { key: index }, [
                              _c("tr", [
                                _c("td", { staticClass: "align-top" }, [
                                  _vm._v(_vm._s(index + 1)),
                                ]),
                                _c(
                                  "td",
                                  { staticClass: "align-top" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "b-toggle",
                                            rawName: "v-b-toggle",
                                            value: "collapse-" + index,
                                            expression: "'collapse-' + index",
                                          },
                                        ],
                                        staticClass: "atmo-button-icon",
                                        staticStyle: { "font-size": "0.8em" },
                                      },
                                      [
                                        !_vm.visible[index]
                                          ? _c(
                                              "span",
                                              {
                                                attrs: {
                                                  title:
                                                    "Показать характеристики",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "atmo-icon-show",
                                                }),
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                attrs: {
                                                  title:
                                                    "Скрыть характеристики",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "atmo-icon-hide",
                                                }),
                                              ]
                                            ),
                                      ]
                                    ),
                                    _c("b-collapse", {
                                      staticClass: "d-none",
                                      attrs: { id: "collapse-" + index },
                                      model: {
                                        value: _vm.visible[index],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.visible, index, $$v)
                                        },
                                        expression: "visible[index]",
                                      },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getPurchaseDeliverable(item.id)
                                            ?.ktru_item?.name
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  { staticClass: "align-top text-nowrap" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getPurchaseDeliverable(item.id)
                                            ?.amount
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getPurchaseDeliverable(item.id)
                                            .ktru_item.okei_local_symbol
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "align-top text-nowrap" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$formatPrice(
                                            _vm.getPurchaseDeliverable(item.id)
                                              .price
                                          )
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$formatPrice(
                                            _vm.getPurchaseDeliverable(item.id)
                                              .total_price
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "align-text-top" },
                                  [
                                    _c("currency-input", {
                                      staticClass:
                                        "form-control fs-14 text-right",
                                      class: _vm.checkItemPrice(item)
                                        ? "is-valid"
                                        : "is-invalid",
                                      attrs: { currency: "RUB", locale: "ru" },
                                      model: {
                                        value: item.price,
                                        callback: function ($$v) {
                                          _vm.$set(item, "price", $$v)
                                        },
                                        expression: "item.price",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  { staticClass: "align-top ws-nowrap" },
                                  [
                                    _vm.form.without_vat
                                      ? [
                                          _c("input", {
                                            staticClass: "form-control fs-14",
                                            attrs: {
                                              disabled: "",
                                              type: "text",
                                              value: "Без НДС",
                                            },
                                          }),
                                        ]
                                      : [
                                          _c("v-select", {
                                            class: {
                                              "is-invalid": item.vat === null,
                                              "d-inline-block": true,
                                              "w-75": true,
                                            },
                                            attrs: {
                                              "calculate-position":
                                                _vm.withPopper,
                                              clearable: false,
                                              options: _vm.dictionaries.vat,
                                              reduce: (item) => item.id,
                                              searchable: false,
                                              "append-to-body": "",
                                              label: "title",
                                              placeholder: "НДС",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "no-options",
                                                  fn: function ({
                                                    search,
                                                    searching,
                                                    loading,
                                                  }) {
                                                    return [
                                                      _vm._v(
                                                        " Записей, соответствующих вашему запросу, не найдено."
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: item.vat,
                                              callback: function ($$v) {
                                                _vm.$set(item, "vat", $$v)
                                              },
                                              expression: "item.vat",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "b-popover",
                                                  rawName:
                                                    "v-b-popover.hover.top",
                                                  value:
                                                    "Применить ко всем позициям спецификации",
                                                  expression:
                                                    "'Применить ко всем позициям спецификации'",
                                                  modifiers: {
                                                    hover: true,
                                                    top: true,
                                                  },
                                                },
                                              ],
                                              staticClass:
                                                "d-inline-block pl-1",
                                            },
                                            [
                                              _c("action-button-small", {
                                                attrs: {
                                                  disabled: item.vat === null,
                                                  icon: "chevron-double-down",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.applyAll(
                                                      item.vat
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "align-top",
                                    staticStyle: { "text-align": "right" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$formatPrice(_vm.itemSum(item))
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "tr",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.visible[index],
                                      expression: "visible[index]",
                                    },
                                  ],
                                },
                                [
                                  _c("td", { attrs: { colspan: 7 } }, [
                                    _vm.getPurchaseDeliverable(item.id)
                                      .ktru_item.reg_number
                                      ? _c("p", [
                                          _c("b", [_vm._v("Рег.номер ТРУ:")]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getPurchaseDeliverable(
                                                  item.id
                                                ).ktru_item.reg_number
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.getPurchaseDeliverable(item.id)
                                      .ktru_item.okpd_code
                                      ? _c("p", [
                                          _c("b", [_vm._v("Код ОКПД2:")]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getPurchaseDeliverable(
                                                  item.id
                                                ).ktru_item.okpd_code
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.getPurchaseDeliverable(item.id)
                                      .ktru_item.description
                                      ? _c("p", [
                                          _c("b", [_vm._v("Описание:")]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getPurchaseDeliverable(
                                                  item.id
                                                ).ktru_item.description
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.getPurchaseDeliverable(item.id)
                                      .ktru_item.specs &&
                                    _vm.getPurchaseDeliverable(item.id)
                                      .ktru_item.specs.length > 0
                                      ? _c("p", [
                                          _c("b", [_vm._v("Характеристики:")]),
                                        ])
                                      : _vm._e(),
                                    _vm.getPurchaseDeliverable(item.id)
                                      .ktru_item.specs &&
                                    _vm.getPurchaseDeliverable(item.id)
                                      .ktru_item.specs.length > 0
                                      ? _c(
                                          "ul",
                                          _vm._l(
                                            _vm.getPurchaseDeliverable(item.id)
                                              .ktru_item.specs,
                                            function (spec, specIndex) {
                                              return _c(
                                                "li",
                                                {
                                                  key:
                                                    index +
                                                    "_spec_" +
                                                    specIndex,
                                                },
                                                [
                                                  _c("b", [
                                                    _vm._v(_vm._s(spec.name)),
                                                  ]),
                                                  _vm._v(
                                                    " - " +
                                                      _vm._s(spec.value) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                            ])
                          }),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "py-2 border-0",
                                attrs: { align: "right", colspan: "7" },
                              },
                              [
                                _c("span", { staticClass: "fs-18 fw-600" }, [
                                  _vm._v(
                                    "Стоимость спецификации предложения с НДС, руб.: " +
                                      _vm._s(_vm.$formatPrice(_vm.totalSum))
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _vm.purchase.supplier_requirements.only_smp ||
                  _vm.purchase.supplier_requirements.only_not_rnp ||
                  _vm.purchase.supplier_requirements.has_additional_requirements
                    ? [
                        _c("text-header", [
                          _vm._v("Подтверждение требований к поставщикам"),
                        ]),
                        _vm.purchase.supplier_requirements.only_smp
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-cols-lg": 11,
                                  "label-cols-sm": 11,
                                  state:
                                    !_vm.$v.form.supplier_requirements_confirmed
                                      .is_smp.$invalid,
                                  label:
                                    "Подтверждаю, что организация является субъектом малого предпринимательства (СМП)",
                                  "label-class":
                                    "fs-14 grey-color fw-700 d-flex align-items-baseline",
                                },
                              },
                              [
                                _c("b-form-checkbox", {
                                  staticClass:
                                    "fs-14 grey-color mt-2 text-right",
                                  attrs: {
                                    state:
                                      !_vm.$v.form
                                        .supplier_requirements_confirmed.is_smp
                                        .$invalid,
                                    disabled: "",
                                  },
                                  model: {
                                    value:
                                      _vm.form.supplier_requirements_confirmed
                                        .is_smp,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form
                                          .supplier_requirements_confirmed,
                                        "is_smp",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.supplier_requirements_confirmed.is_smp",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.purchase.supplier_requirements.only_not_rnp
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-cols-lg": 11,
                                  "label-cols-sm": 11,
                                  state:
                                    !_vm.$v.form.supplier_requirements_confirmed
                                      .is_not_rnp.$invalid,
                                  label:
                                    "Подтверждаю, что организация не присутствует в реестре недобросовестных поставщиков",
                                  "label-class":
                                    "fs-14 grey-color fw-700 d-flex align-items-baseline",
                                },
                              },
                              [
                                _c("b-form-checkbox", {
                                  staticClass:
                                    "fs-14 grey-color mt-2 text-right",
                                  attrs: {
                                    state:
                                      !_vm.$v.form
                                        .supplier_requirements_confirmed
                                        .is_not_rnp.$invalid,
                                  },
                                  model: {
                                    value:
                                      _vm.form.supplier_requirements_confirmed
                                        .is_not_rnp,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form
                                          .supplier_requirements_confirmed,
                                        "is_not_rnp",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.supplier_requirements_confirmed.is_not_rnp",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.purchase.supplier_requirements
                          .has_additional_requirements
                          ? _c("div", { staticClass: "form-row" }, [
                              _c("div", { staticClass: "col" }, [
                                _c(
                                  "p",
                                  { staticClass: "fs-14 grey-color mt-2" },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        "Подтверждаю соответствие дополнительным требованиям, приложив следующие документы:"
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.purchase.supplier_requirements
                          .has_additional_requirements
                          ? _vm._l(
                              _vm.$v.form.supplier_requirements_confirmed
                                .additional.$each.$iter,
                              function (item, index) {
                                return _c(
                                  "form-row-single-line-document-upload",
                                  {
                                    key: index,
                                    attrs: {
                                      "label-cols-lg": 4,
                                      title: _vm.getAdditionalRequirementTitle(
                                        item.$model.additional_requirement_id
                                      ),
                                      v: item,
                                    },
                                    model: {
                                      value: item.$model.confirmation_document,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          item.$model,
                                          "confirmation_document",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "item.$model.confirmation_document",
                                    },
                                  }
                                )
                              }
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _c("form-row-upload-multiple-documents", {
                    attrs: { v: _vm.$v.form.attachments, title: "Документы" },
                    model: {
                      value: _vm.form.attachments,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "attachments", $$v)
                      },
                      expression: "form.attachments",
                    },
                  }),
                  _vm.purchase.fee_amount && !_vm.form.existing_proposal
                    ? _c("p", { staticClass: "font-weight-bold grey-color" }, [
                        _vm._v(
                          "При подаче заявки на лицевом счету Вашей организации, в соответствии с регламентом, будет заблокирована сумма " +
                            _vm._s(_vm.$formatPrice(_vm.purchase.fee_amount)) +
                            " руб."
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "my-5" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-2",
                              attrs: { variant: "custom-outline-secondary" },
                              on: { click: _vm.cancelOffer },
                            },
                            [_vm._v("ОТМЕНИТЬ")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "mx-2",
                              attrs: {
                                disabled:
                                  _vm.$v.$invalid ||
                                  !_vm.$store.getters.getCanSign,
                                variant: "custom-green",
                              },
                              on: { click: _vm.validateForm },
                            },
                            [_vm._v("ПОДАТЬ ПРЕДЛОЖЕНИЕ")]
                          ),
                          !_vm.$store.getters.getCanSign
                            ? _c("span", { staticClass: "text-danger pt-1" }, [
                                _vm._v(
                                  "Подача и изменение предложения возможны только при авторизации при помощи ЭЦП"
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "b-modal",
            {
              ref: "sign-form-modal",
              attrs: {
                centered: "",
                "hide-header-close": "",
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
                size: "lg",
                title: "Подписать предложение",
              },
              model: {
                value: _vm.showSignModal,
                callback: function ($$v) {
                  _vm.showSignModal = $$v
                },
                expression: "showSignModal",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-block text-left" },
                [
                  _c(
                    "b-tabs",
                    { attrs: { "content-class": "mt-3" } },
                    [
                      _c(
                        "b-tab",
                        { attrs: { active: "" } },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "span",
                              { staticClass: "system_link-style fs-16" },
                              [_vm._v("Форма")]
                            ),
                          ]),
                          _c("text-row", {
                            attrs: { label: "Закупочная сессия" },
                            model: {
                              value: _vm.purchase.reg_number,
                              callback: function ($$v) {
                                _vm.$set(_vm.purchase, "reg_number", $$v)
                              },
                              expression: "purchase.reg_number",
                            },
                          }),
                          _c("text-row-price", {
                            attrs: { label: "Итоговая стоимость заявки" },
                            model: {
                              value: _vm.totalSum,
                              callback: function ($$v) {
                                _vm.totalSum = $$v
                              },
                              expression: "totalSum",
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "b-tab",
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "span",
                              { staticClass: "system_link-style fs-16" },
                              [_vm._v("XML")]
                            ),
                          ]),
                          !_vm.isSignedXml
                            ? _c("b-form-textarea", {
                                staticClass: "text-monospace fs-12",
                                attrs: {
                                  disabled: "",
                                  "no-auto-shrink": "",
                                  "no-resize": "",
                                  rows: "20",
                                  wrap: "off",
                                },
                                model: {
                                  value: _vm.formXml,
                                  callback: function ($$v) {
                                    _vm.formXml = $$v
                                  },
                                  expression: "formXml",
                                },
                              })
                            : _vm._e(),
                          _vm.isSignedXml
                            ? _c("b-form-textarea", {
                                staticClass: "text-monospace fs-12",
                                attrs: { disabled: "", rows: "20" },
                                model: {
                                  value: _vm.signedFormXml,
                                  callback: function ($$v) {
                                    _vm.signedFormXml = $$v
                                  },
                                  expression: "signedFormXml",
                                },
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "w-100 px-3",
                  attrs: { slot: "modal-footer" },
                  slot: "modal-footer",
                },
                [
                  _c("text-row", {
                    attrs: {
                      value: _vm.certificate?.subjectName,
                      label: "Сертификат",
                    },
                  }),
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-2 text-uppercase",
                      attrs: {
                        disabled: _vm.isDataSending,
                        variant: "custom-outline-secondary",
                      },
                      on: { click: _vm.cancelSignModal },
                    },
                    [_vm._v("Отмена")]
                  ),
                  !_vm.isSignedXml
                    ? _c(
                        "b-overlay",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            show: _vm.isSigningXml,
                            opacity: "0.6",
                            rounded: "",
                            "spinner-small": "",
                            "spinner-variant": "primary",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase",
                              attrs: {
                                disabled: !_vm.certificate,
                                variant: "custom-green",
                              },
                              on: { click: _vm.signForm },
                            },
                            [_vm._v("Подписать")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isSignedXml
                    ? _c(
                        "b-overlay",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            show: _vm.isDataSending,
                            opacity: "0.6",
                            rounded: "",
                            "spinner-small": "",
                            "spinner-variant": "primary",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase",
                              attrs: {
                                disabled: !_vm.certificate,
                                variant: "custom-green",
                              },
                              on: { click: _vm.sendData },
                            },
                            [_vm._v("Отправить")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c("span", {
            staticClass: "atmo-hash-jump-point",
            attrs: { id: "atmo-bottom" },
          }),
          _vm.loading ? _c("system-preloader") : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }