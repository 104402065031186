<template>
    <div>
        <h3 class="grey-color mb-3 main_text font-weight-bold fs-28">Идентификационные данные пользователя</h3>
        <b-overlay :show="isLoading" opacity="0.6" rounded spinner-variant="primary">
            <text-row :value="profile.name" label="ФИО" />
            <text-row :value="profile.username" description="Запомните или запишите Ваш логин - он будет нужен в случае необходимости сброса пароля" label="Логин" />
            <text-row :value="profile.post" label="Должность" />
            <text-row v-if="initialProfile.inn" :value="profile.inn" label="ИНН" />
            <form-row-edit-text v-else v-model.trim="profile.inn" :v="$v.profile.inn" label="ИНН" />
            <text-row v-if="initialProfile.snils" :value="profile.snils" label="СНИЛС" />
            <form-row-edit-text v-else v-model.trim="profile.snils" :v="$v.profile.snils" label="СНИЛС" />
            <form-row-edit-email
                :email-confirmed="profile.email_confirmed"
                :v="$v.profile.email"
                :value="profile.email"
                autocomplete="email"
                is-edit
                label="Email"
                with-validation
                @input="profile.email_confirmed = false"
                @value="profile.email = $event"
                @email-confirmed="profile.email_confirmed = $event" />
            <form-row-edit-phone :v="$v.profile.phone" :value="profile.phone" label="Телефон" @value="profile.phone = $event" />
            <form-row-edit-text v-model="profile.password" :v="profile.password ? $v.profile.password : null" autocomplete="new-password" label="Новый пароль" type="password" @value="profile.password = $event" />
            <form-row-edit-text v-model="profile.password_confirmation" :v="profile.password ? $v.profile.password_confirmation : null" autocomplete="new-password" label="Подтверждение пароля" type="password" @value="profile.password_confirmation = $event" />
            <form-row-edit-checkbox v-model="$v.profile.signs_with_poa.$model" label="Используется МЧД" />
        </b-overlay>
        <b-row>
            <b-col class="text-right" md="12">
                <b-overlay :show="isLoading" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button :disabled="$v.profile.$invalid || !$v.profile.$anyDirty" class="text-uppercase" variant="custom-green" @click="sendData">Сохранить</b-button>
                </b-overlay>
            </b-col>
        </b-row>

        <b-overlay :show="isLoading" opacity="0.6" rounded spinner-variant="primary">
            <template v-if="profile.certificate">
                <b-row>
                    <b-col md="9">
                        <h3 class="grey-color my-3 main_text font-weight-bold fs-28">Информация о привязанном сертификате</h3>
                    </b-col>
                    <b-col class="text-right my-auto" md="3">
                        <b-button :to="{ name: 'UserChangeCertificate' }" variant="custom-danger">Заменить сертификат</b-button>
                    </b-col>
                </b-row>
                <text-row v-model="profile.certificate.serial" compact label="Серийный номер" />
                <text-row v-model="profile.certificate.thumbprint" compact label="SHA1 отпечаток" />
                <text-row-datetime v-model="profile.certificate.valid_from" compact label="Срок действия, с" />
                <text-row-datetime v-model="profile.certificate.valid_to" compact label="Срок действия, по" />
                <text-row v-model="profile.certificate.subject" compact label="Субъект" />
                <text-row v-model="profile.certificate.issuer" compact label="Издатель" />
            </template>
            <template v-else>
                <b-row>
                    <b-col md="9">
                        <h2 class="text-danger font-weight-bold my-3">Сертификат не привязан</h2>
                    </b-col>
                    <b-col class="text-right my-auto" md="3">
                        <b-button :to="{ name: 'UserChangeCertificate' }" variant="custom-danger">Привязать сертификат</b-button>
                    </b-col>
                </b-row>
            </template>

            <template v-if="initialProfile.signs_with_poa">
                <template v-if="profile.poa">
                    <b-row>
                        <b-col md="9">
                            <h3 class="grey-color my-3 main_text font-weight-bold fs-28">Информация о МЧД</h3>
                        </b-col>
                        <b-col class="text-right my-auto" md="3">
                            <poa-upload-button @upload="fillData">Заменить МЧД</poa-upload-button>
                        </b-col>
                    </b-row>
                    <text-row-link :href="profile.poa.link_check" :value="profile.poa.poa_number" compact label="Номер доверенности" />
                    <text-row :value="profile.poa.principal_inn" compact label="ИНН доверителя" />
                    <text-row :value="profile.poa.representative_inn" compact label="ИНН представителя" />
                    <text-row-datetime v-model="profile.poa.valid_from" compact label="Срок действия, с" />
                    <text-row-datetime v-model="profile.poa.valid_to" compact label="Срок действия, по" />
                </template>
                <template v-else>
                    <b-row>
                        <b-col md="9">
                            <h2 class="text-danger font-weight-bold my-3">МЧД не загружена</h2>
                        </b-col>
                        <b-col class="text-right my-auto" md="3">
                            <poa-upload-button @upload="fillData">Загрузить МЧД</poa-upload-button>
                        </b-col>
                    </b-row>
                </template>
            </template>
        </b-overlay>
    </div>
</template>

<script>
import { email, helpers, maxLength, minLength, numeric, required, requiredIf, sameAs } from 'vuelidate/lib/validators';
import { $api } from '@/services/backend/api';
import PoaUploadButton from '@/components/common/poa-upload-button.vue';

const phone = helpers.regex('phone', /^7[0-9+]{10}$/);
const allowable = helpers.withParams({ type: 'allowable' }, (value) => (value ? /^.*[a-zA-Z0-9-_=+~!@#$%^&*() ,.<>?/\\|`]+$/.test(value) : true));
const containsSymbols = helpers.withParams({ type: 'containsSymbols' }, (value) => (value ? /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value) : true));

export default {
    name: 'UserProfile',
    components: { PoaUploadButton },
    data() {
        return {
            isLoading: false,
            initialProfile: {
                name: '',
                inn: null,
                snils: null,
                email: '',
                email_confirmed: true,
                phone: '',
                post: '',
                username: '',
                password: '',
                password_confirmation: '',
                signs_with_poa: false
            },
            profile: {
                name: '',
                inn: '',
                snils: '',
                email: '',
                email_confirmed: true,
                phone: '',
                post: '',
                username: '',
                password: '',
                password_confirmation: '',
                signs_with_poa: false
            }
        };
    },
    validations: {
        profile: {
            post: { required, minLength: minLength(3) },
            inn: { required, minLength: minLength(12), maxLength: maxLength(12), numeric },
            snils: { required, minLength: minLength(11), maxLength: maxLength(11), numeric },
            phone: { required, phone },
            email: { required, email },
            email_confirmed: { checked: (value) => value === true },
            password: { minLength: minLength(8), allowable, containsSymbols },
            password_confirmation: {
                required: requiredIf((form) => form.password),
                confirmPassword: sameAs((form) => (form.password ? form.password : form.password_confirmation))
            },
            signs_with_poa: {}
        }
    },
    async mounted() {
        await this.fillData();
    },
    methods: {
        async fillData() {
            this.isLoading = true;
            this.profile = await $api.personal.profile.getInformation();
            this.initialProfile = { ...this.profile };
            await this.$nextTick();
            await this.$v.$reset();
            this.isLoading = false;
        },
        onCancel() {
            this.fillData();
        },
        async sendData() {
            this.isLoading = true;
            if (await $api.personal.profile.updateInformation(this.profile)) {
                this.onCancel();
            }
            this.isLoading = false;
        }
    }
};
</script>
