var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "h3",
        { staticClass: "grey-color mb-3 main_text font-weight-bold fs-28" },
        [_vm._v("Идентификационные данные пользователя")]
      ),
      _c(
        "b-overlay",
        {
          attrs: {
            show: _vm.isLoading,
            opacity: "0.6",
            rounded: "",
            "spinner-variant": "primary",
          },
        },
        [
          _c("text-row", { attrs: { value: _vm.profile.name, label: "ФИО" } }),
          _c("text-row", {
            attrs: {
              value: _vm.profile.username,
              description:
                "Запомните или запишите Ваш логин - он будет нужен в случае необходимости сброса пароля",
              label: "Логин",
            },
          }),
          _c("text-row", {
            attrs: { value: _vm.profile.post, label: "Должность" },
          }),
          _vm.initialProfile.inn
            ? _c("text-row", {
                attrs: { value: _vm.profile.inn, label: "ИНН" },
              })
            : _c("form-row-edit-text", {
                attrs: { v: _vm.$v.profile.inn, label: "ИНН" },
                model: {
                  value: _vm.profile.inn,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.profile,
                      "inn",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "profile.inn",
                },
              }),
          _vm.initialProfile.snils
            ? _c("text-row", {
                attrs: { value: _vm.profile.snils, label: "СНИЛС" },
              })
            : _c("form-row-edit-text", {
                attrs: { v: _vm.$v.profile.snils, label: "СНИЛС" },
                model: {
                  value: _vm.profile.snils,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.profile,
                      "snils",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "profile.snils",
                },
              }),
          _c("form-row-edit-email", {
            attrs: {
              "email-confirmed": _vm.profile.email_confirmed,
              v: _vm.$v.profile.email,
              value: _vm.profile.email,
              autocomplete: "email",
              "is-edit": "",
              label: "Email",
              "with-validation": "",
            },
            on: {
              input: function ($event) {
                _vm.profile.email_confirmed = false
              },
              value: function ($event) {
                _vm.profile.email = $event
              },
              "email-confirmed": function ($event) {
                _vm.profile.email_confirmed = $event
              },
            },
          }),
          _c("form-row-edit-phone", {
            attrs: {
              v: _vm.$v.profile.phone,
              value: _vm.profile.phone,
              label: "Телефон",
            },
            on: {
              value: function ($event) {
                _vm.profile.phone = $event
              },
            },
          }),
          _c("form-row-edit-text", {
            attrs: {
              v: _vm.profile.password ? _vm.$v.profile.password : null,
              autocomplete: "new-password",
              label: "Новый пароль",
              type: "password",
            },
            on: {
              value: function ($event) {
                _vm.profile.password = $event
              },
            },
            model: {
              value: _vm.profile.password,
              callback: function ($$v) {
                _vm.$set(_vm.profile, "password", $$v)
              },
              expression: "profile.password",
            },
          }),
          _c("form-row-edit-text", {
            attrs: {
              v: _vm.profile.password
                ? _vm.$v.profile.password_confirmation
                : null,
              autocomplete: "new-password",
              label: "Подтверждение пароля",
              type: "password",
            },
            on: {
              value: function ($event) {
                _vm.profile.password_confirmation = $event
              },
            },
            model: {
              value: _vm.profile.password_confirmation,
              callback: function ($$v) {
                _vm.$set(_vm.profile, "password_confirmation", $$v)
              },
              expression: "profile.password_confirmation",
            },
          }),
          _c("form-row-edit-checkbox", {
            attrs: { label: "Используется МЧД" },
            model: {
              value: _vm.$v.profile.signs_with_poa.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.profile.signs_with_poa, "$model", $$v)
              },
              expression: "$v.profile.signs_with_poa.$model",
            },
          }),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "text-right", attrs: { md: "12" } },
            [
              _c(
                "b-overlay",
                {
                  attrs: {
                    show: _vm.isLoading,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: {
                        disabled:
                          _vm.$v.profile.$invalid || !_vm.$v.profile.$anyDirty,
                        variant: "custom-green",
                      },
                      on: { click: _vm.sendData },
                    },
                    [_vm._v("Сохранить")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-overlay",
        {
          attrs: {
            show: _vm.isLoading,
            opacity: "0.6",
            rounded: "",
            "spinner-variant": "primary",
          },
        },
        [
          _vm.profile.certificate
            ? [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { md: "9" } }, [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "grey-color my-3 main_text font-weight-bold fs-28",
                        },
                        [_vm._v("Информация о привязанном сертификате")]
                      ),
                    ]),
                    _c(
                      "b-col",
                      { staticClass: "text-right my-auto", attrs: { md: "3" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              to: { name: "UserChangeCertificate" },
                              variant: "custom-danger",
                            },
                          },
                          [_vm._v("Заменить сертификат")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("text-row", {
                  attrs: { compact: "", label: "Серийный номер" },
                  model: {
                    value: _vm.profile.certificate.serial,
                    callback: function ($$v) {
                      _vm.$set(_vm.profile.certificate, "serial", $$v)
                    },
                    expression: "profile.certificate.serial",
                  },
                }),
                _c("text-row", {
                  attrs: { compact: "", label: "SHA1 отпечаток" },
                  model: {
                    value: _vm.profile.certificate.thumbprint,
                    callback: function ($$v) {
                      _vm.$set(_vm.profile.certificate, "thumbprint", $$v)
                    },
                    expression: "profile.certificate.thumbprint",
                  },
                }),
                _c("text-row-datetime", {
                  attrs: { compact: "", label: "Срок действия, с" },
                  model: {
                    value: _vm.profile.certificate.valid_from,
                    callback: function ($$v) {
                      _vm.$set(_vm.profile.certificate, "valid_from", $$v)
                    },
                    expression: "profile.certificate.valid_from",
                  },
                }),
                _c("text-row-datetime", {
                  attrs: { compact: "", label: "Срок действия, по" },
                  model: {
                    value: _vm.profile.certificate.valid_to,
                    callback: function ($$v) {
                      _vm.$set(_vm.profile.certificate, "valid_to", $$v)
                    },
                    expression: "profile.certificate.valid_to",
                  },
                }),
                _c("text-row", {
                  attrs: { compact: "", label: "Субъект" },
                  model: {
                    value: _vm.profile.certificate.subject,
                    callback: function ($$v) {
                      _vm.$set(_vm.profile.certificate, "subject", $$v)
                    },
                    expression: "profile.certificate.subject",
                  },
                }),
                _c("text-row", {
                  attrs: { compact: "", label: "Издатель" },
                  model: {
                    value: _vm.profile.certificate.issuer,
                    callback: function ($$v) {
                      _vm.$set(_vm.profile.certificate, "issuer", $$v)
                    },
                    expression: "profile.certificate.issuer",
                  },
                }),
              ]
            : [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { md: "9" } }, [
                      _c(
                        "h2",
                        { staticClass: "text-danger font-weight-bold my-3" },
                        [_vm._v("Сертификат не привязан")]
                      ),
                    ]),
                    _c(
                      "b-col",
                      { staticClass: "text-right my-auto", attrs: { md: "3" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              to: { name: "UserChangeCertificate" },
                              variant: "custom-danger",
                            },
                          },
                          [_vm._v("Привязать сертификат")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
          _vm.initialProfile.signs_with_poa
            ? [
                _vm.profile.poa
                  ? [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "9" } }, [
                            _c(
                              "h3",
                              {
                                staticClass:
                                  "grey-color my-3 main_text font-weight-bold fs-28",
                              },
                              [_vm._v("Информация о МЧД")]
                            ),
                          ]),
                          _c(
                            "b-col",
                            {
                              staticClass: "text-right my-auto",
                              attrs: { md: "3" },
                            },
                            [
                              _c(
                                "poa-upload-button",
                                { on: { upload: _vm.fillData } },
                                [_vm._v("Заменить МЧД")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("text-row-link", {
                        attrs: {
                          href: _vm.profile.poa.link_check,
                          value: _vm.profile.poa.poa_number,
                          compact: "",
                          label: "Номер доверенности",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          value: _vm.profile.poa.principal_inn,
                          compact: "",
                          label: "ИНН доверителя",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          value: _vm.profile.poa.representative_inn,
                          compact: "",
                          label: "ИНН представителя",
                        },
                      }),
                      _c("text-row-datetime", {
                        attrs: { compact: "", label: "Срок действия, с" },
                        model: {
                          value: _vm.profile.poa.valid_from,
                          callback: function ($$v) {
                            _vm.$set(_vm.profile.poa, "valid_from", $$v)
                          },
                          expression: "profile.poa.valid_from",
                        },
                      }),
                      _c("text-row-datetime", {
                        attrs: { compact: "", label: "Срок действия, по" },
                        model: {
                          value: _vm.profile.poa.valid_to,
                          callback: function ($$v) {
                            _vm.$set(_vm.profile.poa, "valid_to", $$v)
                          },
                          expression: "profile.poa.valid_to",
                        },
                      }),
                    ]
                  : [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "9" } }, [
                            _c(
                              "h2",
                              {
                                staticClass:
                                  "text-danger font-weight-bold my-3",
                              },
                              [_vm._v("МЧД не загружена")]
                            ),
                          ]),
                          _c(
                            "b-col",
                            {
                              staticClass: "text-right my-auto",
                              attrs: { md: "3" },
                            },
                            [
                              _c(
                                "poa-upload-button",
                                { on: { upload: _vm.fillData } },
                                [_vm._v("Загрузить МЧД")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }