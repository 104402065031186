var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new_quotation" },
    [
      _vm.loading ? _c("system-preloader") : _vm._e(),
      _c(
        "b-container",
        { staticClass: "main_layer", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "p",
                      { staticClass: "fs-40 fw-600 white-color mt-3 mb-3" },
                      [
                        _vm._v("Подача коммерческого предложения"),
                        _c("br"),
                        _vm._v(
                          "на запрос КП № " +
                            _vm._s(_vm.quotationRequest.reg_number)
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        { staticClass: "pb-4", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c("text-header", [_vm._v("Информация о заказчике")]),
              _c("text-row-link", {
                attrs: {
                  "router-link": {
                    name: "OrganizationShow",
                    params: { id: _vm.quotationRequest.organization.id },
                  },
                  value: _vm.quotationRequest.organization.name,
                  compact: "",
                  label: "Наименование",
                },
              }),
              _c("text-row", {
                attrs: {
                  value: _vm.quotationRequest.organization.inn,
                  compact: "",
                  label: "ИНН",
                },
              }),
              _c("text-row", {
                attrs: {
                  value: _vm.quotationRequest.organization.kpp,
                  compact: "",
                  label: "КПП",
                },
              }),
              _c("text-row", {
                attrs: {
                  value: _vm.quotationRequest.organization.ogrn,
                  compact: "",
                  label: "ОГРН",
                },
              }),
              _c("text-row", {
                attrs: {
                  value: _vm.quotationRequest.organization.address,
                  compact: "",
                  label: "Адрес",
                },
              }),
              _c(
                "text-row-raw",
                { attrs: { compact: "", label: "Адрес электронной почты" } },
                [
                  _c(
                    "a",
                    {
                      staticClass: "fs-14 col-form-label d-inline-block",
                      attrs: {
                        href:
                          "mailto:" + _vm.quotationRequest.organization.email,
                      },
                    },
                    [_vm._v(_vm._s(_vm.quotationRequest.organization.email))]
                  ),
                ]
              ),
              _c(
                "text-row-raw",
                { attrs: { compact: "", label: "Контактный номер телефона" } },
                [
                  _c(
                    "a",
                    {
                      staticClass: "fs-14 col-form-label d-inline-block",
                      attrs: {
                        href: "tel:+" + _vm.quotationRequest.organization.phone,
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$parsePhone(
                            _vm.quotationRequest.organization.phone
                          )
                        )
                      ),
                    ]
                  ),
                ]
              ),
              _c("text-header", [_vm._v("Информация о поставщике")]),
              _c("text-row-link", {
                attrs: {
                  "router-link": {
                    name: "OrganizationShow",
                    params: { id: _vm.$store.state.organization.id },
                  },
                  value: _vm.$store.state.organization.name,
                  compact: "",
                  label: "Наименование",
                },
              }),
              _c("text-row", {
                attrs: {
                  value: _vm.$store.state.organization.inn,
                  compact: "",
                  label: "ИНН",
                },
              }),
              _c("text-row", {
                attrs: {
                  value: _vm.$store.state.organization.kpp,
                  compact: "",
                  label: "КПП",
                },
              }),
              _c("text-row", {
                attrs: {
                  value: _vm.$store.state.organization.ogrn,
                  compact: "",
                  label: "ОГРН",
                },
              }),
              _c("text-row", {
                attrs: {
                  value: _vm.$store.state.organization.post_address,
                  compact: "",
                  label: "Адрес",
                },
              }),
              _c("text-header", [_vm._v("Информация о запросе КП")]),
              _c("text-row", {
                attrs: {
                  value: _vm.quotationRequest.reg_number,
                  compact: "",
                  label: "Рег.номер запроса КП",
                },
              }),
              _c("text-row", {
                attrs: {
                  value: _vm.quotationRequest.name,
                  compact: "",
                  label: "Предмет закупки",
                },
              }),
              _c("text-row-datetime", {
                attrs: {
                  value: _vm.quotationRequest.deadline,
                  compact: "",
                  label: "Срок сбора КП",
                },
              }),
              _c("text-row-single-document", {
                attrs: {
                  value: _vm.quotationRequest.document,
                  compact: "",
                  label: "Документ",
                },
              }),
              _c("text-header", [
                _vm._v("Информация о коммерческом предложении"),
              ]),
              _c("form-row-edit-date", {
                attrs: {
                  "min-date": new Date().toJSON().slice(0, 10),
                  v: _vm.$v.form.deadline,
                  label: "Срок действия коммерческого предложения",
                },
                model: {
                  value: _vm.form.deadline,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deadline", $$v)
                  },
                  expression: "form.deadline",
                },
              }),
              _c("form-row-specification-table", {
                attrs: {
                  "spec-error-message": _vm.specErrorMessage,
                  "spec-errors": _vm.specErrors,
                  "spec-sum": _vm.specSum,
                  v: _vm.$v.form.specs,
                  "no-add-spec": "",
                  "no-delete-spec": "",
                  "with-price": "",
                },
                model: {
                  value: _vm.form.specs,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "specs", $$v)
                  },
                  expression: "form.specs",
                },
              }),
              _c("form-row-single-line-document-upload", {
                attrs: {
                  "label-cols-lg": 3,
                  v: _vm.$v.form.document,
                  title: "Документ",
                },
                model: {
                  value: _vm.form.document,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "document", $$v)
                  },
                  expression: "form.document",
                },
              }),
              _c(
                "b-row",
                { staticClass: "mb-4" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-3",
                          attrs: { variant: "custom-outline-secondary" },
                          on: { click: _vm.cancelQuotation },
                        },
                        [_vm._v("Отменить")]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled:
                              !_vm.isValid || !_vm.$store.getters.getCanSign,
                            variant: "custom-green",
                          },
                          on: { click: _vm.prepareForm },
                        },
                        [_vm._v("Подписать и подать предложение")]
                      ),
                      !_vm.$store.getters.getCanSign
                        ? _c("span", { staticClass: "text-danger pt-1" }, [
                            _vm._v(
                              "Подписание возможно только при авторизации при помощи ЭЦП"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "sign-form-modal",
          attrs: {
            centered: "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            size: "lg",
            title: "Подписать предложение",
          },
          on: { close: _vm.cancelSignModal },
          model: {
            value: _vm.showSignModal,
            callback: function ($$v) {
              _vm.showSignModal = $$v
            },
            expression: "showSignModal",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-block text-left" },
            [
              _c(
                "b-tabs",
                { attrs: { "content-class": "mt-3" } },
                [
                  _c(
                    "b-tab",
                    {
                      attrs: { active: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                { staticClass: "system_link-style fs-16" },
                                [_vm._v("Форма")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("h4", { staticClass: "font-weight-bold" }, [
                        _vm._v("Информация о коммерческом предложении"),
                      ]),
                      _c("text-row", {
                        attrs: {
                          value: _vm.quotationRequest.reg_number,
                          compact: "",
                          label: "Рег. номер запроса КП",
                        },
                      }),
                      _c("text-row-price", {
                        attrs: {
                          value: _vm.specSum,
                          compact: "",
                          label: "Общая стоимость предложения",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                { staticClass: "system_link-style fs-16" },
                                [_vm._v("Xml представление")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      !_vm.isSignedXml
                        ? _c("b-form-textarea", {
                            staticClass: "text-monospace fs-12",
                            attrs: {
                              disabled: "",
                              "no-auto-shrink": "",
                              "no-resize": "",
                              rows: "20",
                              wrap: "off",
                            },
                            model: {
                              value: _vm.formXml,
                              callback: function ($$v) {
                                _vm.formXml = $$v
                              },
                              expression: "formXml",
                            },
                          })
                        : _vm._e(),
                      _vm.isSignedXml
                        ? _c("b-form-textarea", {
                            staticClass: "text-monospace fs-12",
                            attrs: {
                              disabled: "",
                              "no-auto-shrink": "",
                              "no-resize": "",
                              rows: "20",
                              wrap: "off",
                            },
                            model: {
                              value: _vm.signedFormXml,
                              callback: function ($$v) {
                                _vm.signedFormXml = $$v
                              },
                              expression: "signedFormXml",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c("text-row", {
                attrs: {
                  value: _vm.certificate?.subjectName,
                  label: "Сертификат",
                },
              }),
              _c(
                "b-button",
                {
                  staticClass: "mr-2 text-uppercase",
                  attrs: {
                    disabled: _vm.isDataSending || _vm.isSigningXml,
                    variant: "custom-outline-secondary",
                  },
                  on: { click: _vm.cancelSignModal },
                },
                [_vm._v("Отмена")]
              ),
              !_vm.isSignedXml
                ? _c(
                    "b-overlay",
                    {
                      staticClass: "d-inline-block",
                      attrs: {
                        show: _vm.isSigningXml,
                        opacity: "0.6",
                        rounded: "",
                        "spinner-small": "",
                        "spinner-variant": "primary",
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            disabled: !_vm.certificate,
                            variant: "custom-green",
                          },
                          on: { click: _vm.signForm },
                        },
                        [_vm._v("Подписать")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isSignedXml
                ? _c(
                    "b-overlay",
                    {
                      staticClass: "d-inline-block",
                      attrs: {
                        show: _vm.isDataSending,
                        opacity: "0.6",
                        rounded: "",
                        "spinner-small": "",
                        "spinner-variant": "primary",
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            disabled: !_vm.certificate,
                            variant: "custom-green",
                          },
                          on: { click: _vm.sendData },
                        },
                        [_vm._v("Отправить")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }