var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("organization-reliability-report-controller", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (orrc) {
          return [
            _c("div", { staticClass: "atmo-page-content atmo-client-space" }, [
              _c("header", [
                _c(
                  "h3",
                  {
                    staticClass:
                      "grey-color mb-4 main_text font-weight-bold fs-28",
                  },
                  [_vm._v("Проверка контрагентов")]
                ),
                _c("p", [
                  _vm._v(" Скачайте пример отчета по "),
                  _c("b", [
                    _c(
                      "a",
                      {
                        staticStyle: {
                          "text-decoration": "underline",
                          color: "var(--green-color)",
                        },
                        attrs: {
                          target: "_blank",
                          href: orrc.report_example_link,
                        },
                      },
                      [_vm._v("ссылке")]
                    ),
                  ]),
                  _vm._v(". "),
                ]),
                _c("p", [
                  _vm._v(" Стоимость одно запроса по организации составляет "),
                  orrc.vmdservice.viewables.financials.is_empty
                    ? _c(
                        "span",
                        { staticClass: "atmo-skeleton-placeholder-item" },
                        [_vm._v(" ")]
                      )
                    : _c("b", [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              orrc.vmdservice.viewables.financials.report_price
                            ),
                          },
                        }),
                        _vm._v(" руб."),
                      ]),
                  _vm._v(
                    " Эта сумма будет списана с лицевого счета Вашей организации в момент скачивания отчета. "
                  ),
                  _c("br"),
                  _vm._v("Остаток на лицевом счете составляет: "),
                  orrc.vmdservice.viewables.financials.is_empty
                    ? _c(
                        "span",
                        { staticClass: "atmo-skeleton-placeholder-item" },
                        [_vm._v("  ")]
                      )
                    : _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatnumber")(
                              orrc.vmdservice.viewables.financials
                                .deposit_balance
                            )
                          )
                        ),
                      ]),
                  _vm._v(" руб. "),
                ]),
              ]),
              !orrc.is_form_visible &&
              !orrc.vmdservice.viewables.is_loading.financials
                ? _c("p", { staticClass: "atmo-error" }, [
                    _vm._v(
                      "Остатка на лицевом счете недостаточно для оплаты отчета. Пожалуйста, пополните лицевой счет и перезагрузите эту страницу."
                    ),
                  ])
                : _vm._e(),
              orrc.is_form_visible
                ? _c(
                    "main",
                    [
                      _c(
                        "section",
                        { staticClass: "atmo-form__field atmo-inverse" },
                        [
                          _c(
                            "span",
                            {
                              key: orrc.vmdservice.viewables.refresh_key,
                              staticClass: "atmo-input-w-spinner",
                            },
                            [
                              _c("clearable-input", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ci) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "atmo-clearable-input",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      orrc.vmdservice.editable
                                                        .inn,
                                                    expression:
                                                      "orrc.vmdservice.editable.inn",
                                                  },
                                                  {
                                                    name: "focus",
                                                    rawName: "v-focus",
                                                  },
                                                  {
                                                    name: "input-int",
                                                    rawName: "v-input-int",
                                                    value: true,
                                                    expression: "true",
                                                  },
                                                ],
                                                staticStyle: { width: "auto" },
                                                attrs: {
                                                  id: "orr-inn",
                                                  autocomplete: "off",
                                                  maxlength: "12",
                                                  minlength: "10",
                                                  pattern: "\\d{10}|\\d{12}",
                                                  required: "",
                                                  size: "14",
                                                  type: "tel",
                                                },
                                                domProps: {
                                                  value:
                                                    orrc.vmdservice.editable
                                                      .inn,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      orrc.vmdservice.editable,
                                                      "inn",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              !ci.isEmpty()
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass: "atmo-clear",
                                                      attrs: {
                                                        tabindex: "-1",
                                                        title:
                                                          "Очистить поле ввода",
                                                      },
                                                      on: { click: ci.clear },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "atmo-icon-close",
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("transition", { attrs: { name: "fade" } }, [
                                orrc.vmdservice.viewables.is_loading
                                  .organization
                                  ? _c("div", { staticClass: "atmo-spinner" }, [
                                      _c("img", {
                                        attrs: {
                                          alt: "Waiting spinner",
                                          src: require("@theme/assets/images/loaders/spinner-sector.svg"),
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                          _c("label", { attrs: { for: "orr-inn" } }, [
                            _vm._v(
                              "Введите ИНН. 10-значный (для организаций) или 12-значный (для ИП)."
                            ),
                          ]),
                        ]
                      ),
                      orrc.is_not_found_message_visble
                        ? _c("p", { staticClass: "atmo-error" }, [
                            _vm._v("Организация с таким ИНН не найдена."),
                          ])
                        : _vm._e(),
                      !orrc.vmdservice.editable.is_inn_valid
                        ? _c("p", { staticClass: "atmo-error" }, [
                            _vm._v("ИНН должен содержать 10 или 12 цифр."),
                          ])
                        : _vm._e(),
                      orrc.vmdservice.viewables.is_found
                        ? [
                            _c("h6", [_vm._v("Найдена организация")]),
                            _c("OrganizationInfo.view", {
                              attrs: {
                                organization:
                                  orrc.vmdservice.viewables.organization,
                              },
                            }),
                            _c("ul", { staticClass: "atmo-inputs-inline" }, [
                              _c(
                                "li",
                                {
                                  staticClass: "atmo-form__field atmo-checkbox",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          orrc.vmdservice.editable
                                            .has_to_email_report,
                                        expression:
                                          "orrc.vmdservice.editable.has_to_email_report",
                                      },
                                    ],
                                    staticClass: "atmo-icon-checkmark",
                                    attrs: {
                                      id: "orr-email",
                                      name: "orr-email",
                                      type: "checkbox",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        orrc.vmdservice.editable
                                          .has_to_email_report
                                      )
                                        ? _vm._i(
                                            orrc.vmdservice.editable
                                              .has_to_email_report,
                                            null
                                          ) > -1
                                        : orrc.vmdservice.editable
                                            .has_to_email_report,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            orrc.vmdservice.editable
                                              .has_to_email_report,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                orrc.vmdservice.editable,
                                                "has_to_email_report",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                orrc.vmdservice.editable,
                                                "has_to_email_report",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            orrc.vmdservice.editable,
                                            "has_to_email_report",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _c("label", { attrs: { for: "orr-email" } }, [
                                    _vm._v("Отправить отчет на email:"),
                                  ]),
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "atmo-form__field atmo-inverse",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: orrc.vmdservice.editable.email,
                                        expression:
                                          "orrc.vmdservice.editable.email",
                                      },
                                    ],
                                    attrs: {
                                      required: "",
                                      size: "50",
                                      type: "email",
                                      disabled:
                                        !orrc.vmdservice.editable
                                          .has_to_email_report,
                                    },
                                    domProps: {
                                      value: orrc.vmdservice.editable.email,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          orrc.vmdservice.editable,
                                          "email",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c("p", { staticClass: "atmo-error" }, [
                                    _vm._v("Укажите правильный email"),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("p", [
                              _c("i", {
                                staticClass: "atmo-warning atmo-icon-warning",
                              }),
                              _vm._v(
                                " Внимание! Повторное скачивание отчета без оплаты недоступно. "
                              ),
                              _c("br"),
                              _vm._v(
                                "Рекомендуем отправить отчет на электронную почту. Для этого установите галочку напротив данной опции выше и укажите актуальный адрес."
                              ),
                            ]),
                            _c(
                              "button",
                              {
                                staticClass: "atmo-button mx-2",
                                attrs: {
                                  disabled:
                                    !orrc.vmdservice.editable.is_email_valid ||
                                    !_vm.$store.getters.getCanSign,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return orrc.invokeSignReportDownloadPopup.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v("Подтвердить")]
                            ),
                            !_vm.$store.getters.getCanSign
                              ? _c(
                                  "span",
                                  { staticClass: "text-danger pt-1" },
                                  [
                                    _vm._v(
                                      "Скачивание отчёта возможно только при авторизации при помощи ЭЦП"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }